import type { PopoverProps } from '@byecode/ui'
import { Empty, IconFont, Popover, usePopoverHeight } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import { Divider } from '@mantine/core'
import type { FloatingPosition } from '@mantine/core/lib/Floating'
import { find, max, min, reduce } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useClickAway, useUpdateEffect } from 'react-use'

import { CURRENT_USER, PERSON_ID, PERSON_MAP_NAME } from '../../constants'
import { popoverMaxHeight } from '../FieldInput/constant'
import { SelectItemPreviewer } from './SelectItemPreviewer'
import * as SC from './styles'
import { TagItemPreviewer } from './TagItemPreviewer'

// type OptionItem = { label: string; value: string; color?: string; isDelete?: boolean }
type PersonSelectStyles = 'rightSection' | 'container' | 'value' | 'dropContainer'

export { TagItemPreviewer } from './TagItemPreviewer'

interface PersonSelectProps extends Pick<PopoverProps, 'position'>{
    options: AppUser[]
    value?: string[]
    defaultValue?: string[]
    width?: string
    placeholder?: string
    rightProperty?: keyof AppUser
    withinPortal?: boolean
    color?: string
    clearable?: boolean
    tagClearable?: boolean
    style?: React.CSSProperties
    enableCheckBox?: boolean
    enableCurrentUser?: boolean
    searchPlaceholder?: string
    readOnly?: boolean
    isHiddenUserId?: boolean
    isMultiple?: boolean
    isBorder?: boolean
    isHoverShowIcon?: boolean
    isWrap?: boolean
    focusOutLine?: string
    dropDownWidth?: string
    minWidth?: number
    emptyDesc?: string
    valueComponent?: React.ReactNode
    extraComponent?: React.FC<{ onClose?: () => void }>
    styles?: Partial<Record<PersonSelectStyles, React.CSSProperties>>
    disabled?: boolean
    onVisibleChange?: (open: boolean) => void
    onChange?: (value: string[]) => void
}

export const PersonSelect: React.FunctionComponent<PersonSelectProps> = ({
    options,
    value,
    defaultValue = [],
    width,
    placeholder,
    withinPortal,
    color,
    isMultiple = true,
    clearable = true,
    tagClearable,
    isBorder = true,
    isHiddenUserId = false,
    readOnly,
    position = 'bottom-start',
    searchPlaceholder = '搜索',
    emptyDesc = '没有数据',
    enableCheckBox = true,
    isHoverShowIcon = false,
    rightProperty = 'userId',
    enableCurrentUser,
    isWrap,
    focusOutLine,
    styles,
    valueComponent,
    extraComponent,
    minWidth,
    disabled,
    onVisibleChange,
    onChange
}) => {
    const contentRef = useRef<HTMLButtonElement>(null)
    const [opened, setOpened] = useState(false)
    const dropDownRef = useRef<HTMLDivElement>(null)

    const [currentValue, setCurrentValue] = useState(value?.filter(Boolean) ?? defaultValue)
    const [searchWord, setSearchWord] = useState('')

    const currentOptions = useMemo(
        () =>
            options.filter(
                item =>
                    (item.username.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase()) ||
                        item.userId.toLocaleLowerCase().includes(searchWord.toLocaleLowerCase())) &&
                    !item.isDepart
            ),
        [options, searchWord]
    )

    const { height: tableHeight, ref } = usePopoverHeight(opened, popoverMaxHeight)

    const isEmptyOptions = useMemo(() => currentOptions.length === 0, [currentOptions.length])

    const isEmptyValue = useMemo(() => currentValue.length === 0, [currentValue.length])

    const isShowRightSection = useMemo(() => {
        if (clearable) {
            return isEmptyValue
        }
        return !readOnly && !isHoverShowIcon
    }, [clearable, isEmptyValue, isHoverShowIcon, readOnly])

    const isShowClearIcon = useMemo(() => {
        return clearable && !isEmptyValue
    }, [clearable, isEmptyValue])

    const currentTags = useMemo(() => {
        return reduce<string, AppUser[]>(
            (preVal, userId) => {
                if (userId === '{currentUserId}') {
                    return [...preVal, CURRENT_USER]
                }
                if (PERSON_ID.has(userId)) {
                    return [...preVal, { userId, username: PERSON_MAP_NAME[userId], mobile: '', uniqueUserId: userId }]
                }
                const item = find(item => item.userId === userId, options)
                if (!item) {
                    return preVal
                }
                if (item.isDepart) {
                    return [...preVal, { ...item, username: `${item.username}(已注销)` }]
                }
                return [...preVal, item]
            },
            [],
            currentValue
        )
    }, [currentValue, options])

    useUpdateEffect(() => {
        if (currentValue !== value) {
            setCurrentValue(value ?? defaultValue)
        }
    }, [value])

    const handleClear = useCallback(
        (event: React.MouseEvent<HTMLSpanElement>) => {
            event.stopPropagation()
            setCurrentValue([])
            onChange?.([])
        },
        [onChange]
    )

    const handleOpen = useCallback(() => {
        if (readOnly) {
            return
        }
        setOpened(draft => !draft)
        onVisibleChange?.(!opened)
    }, [onVisibleChange, opened, readOnly])

    const handleClose = useCallback(() => {
        setOpened(false)
        onVisibleChange?.(false)
    }, [onVisibleChange])

    const handMultipleChange = useCallback(
        (id: string) => {
            const isExit = find(userId => userId === id, currentValue)
            if (isMultiple) {
                const filterValue = currentValue.filter(userId => userId !== id)
                const newValue = isExit ? filterValue : [...filterValue, id]
                setCurrentValue(newValue)
                onChange?.(newValue)
                return
            }
            const newValue = isExit ? [] : [id]
            setCurrentValue(newValue)
            onChange?.(newValue)
            handleClose()
        },
        [currentValue, handleClose, isMultiple, onChange]
    )

    const handleRemove = useCallback(
        (id: string) => {
            handMultipleChange(id)
        },
        [handMultipleChange]
    )

    return (
        <Popover
            opened={opened}
            withinPortal={withinPortal}
            offsetOptions={{ mainAxis: 16 }}
            position={position}
            onClose={handleClose}
            closeOnEscape
            disabled={disabled}
            minWidth={minWidth}
            width="auto"
            // positionDependencies={[currentOptions]}
        >
            <Popover.Target>
                <SC.PersonContainer
                    ref={contentRef}
                    width={width}
                    isBorder={isBorder}
                    focusOutLine={focusOutLine}
                    style={styles?.container}
                    type="button"
                >
                    <SC.ValueContainer onClick={handleOpen} style={styles?.value} ref={ref}>
                        {valueComponent ?? (
                            <>
                                <SC.TagWrapper isWrap={isWrap}>
                                    {currentTags.map(item => (
                                        <TagItemPreviewer
                                            key={item.userId}
                                            data={item}
                                            isHiddenUserId={isHiddenUserId}
                                            clearable={!readOnly && tagClearable}
                                            onRemove={handleRemove}
                                        />
                                    ))}
                                    {isEmptyValue && <SC.PlaceHolder>{placeholder}</SC.PlaceHolder>}
                                </SC.TagWrapper>
                                <SC.IconWrapper style={styles?.rightSection}>
                                    {isShowRightSection && <SC.ArrowIcon type="ArrowDownSmall" size={16} color="var(--color-gray-400)" />}
                                    {isShowClearIcon && (
                                        <SC.ClearIcon size={16} color="var(--color-gray-400)" onClick={handleClear} type="CloseCircle" />
                                    )}
                                </SC.IconWrapper>
                            </>
                        )}
                    </SC.ValueContainer>
                </SC.PersonContainer>
            </Popover.Target>
            <Popover.Dropdown compact>
                <SC.DropDownContainer
                    data-type="fl-node-part"
                    width={width}
                    ref={dropDownRef}
                    popoverHeight={tableHeight}
                    style={styles?.dropContainer}
                >
                    <SC.SearchInput
                        value={searchWord}
                        autoFocus
                        onChange={e => setSearchWord(e.target.value)}
                        onBlur={e => setSearchWord(e.target.value)}
                        placeholder={searchPlaceholder}
                        prefix={<IconFont type="Search" size={16} color="var(--color-gray-500)" />}
                    />
                    <SC.DropDownContent>
                        <SC.List>
                            {searchWord === '' && (
                                <>
                                    {extraComponent?.({ onClose: handleClose })}
                                    {enableCurrentUser && (
                                        <>
                                            <SC.DropDownItem onClick={() => handMultipleChange(CURRENT_USER.userId)}>
                                                <SelectItemPreviewer
                                                    data={CURRENT_USER}
                                                    value={currentValue}
                                                    rightProperty={rightProperty}
                                                    color={color}
                                                    enableCheckBox={enableCheckBox}
                                                    isVisibleRightSection={false}
                                                />
                                            </SC.DropDownItem>
                                            <Divider style={{ margin: '8px 0' }} />
                                        </>
                                    )}
                                </>
                            )}
                            {currentOptions.map(item => (
                                <SC.DropDownItem key={item.userId} onClick={() => handMultipleChange(item.userId)}>
                                    <SelectItemPreviewer
                                        data={item}
                                        color={color}
                                        rightProperty={rightProperty}
                                        isVisibleRightSection={!isHiddenUserId}
                                        value={currentValue}
                                        enableCheckBox={enableCheckBox}
                                    />
                                </SC.DropDownItem>
                            ))}
                            {isEmptyOptions && (
                                <Empty
                                    styles={{
                                        root: {
                                            height: 110
                                        }
                                    }}
                                    icon={<IconFont type="User" size={16} color="var(--color-gray-400)" />}
                                    description={emptyDesc}
                                />
                            )}
                        </SC.List>
                    </SC.DropDownContent>
                </SC.DropDownContainer>
            </Popover.Dropdown>
        </Popover>
    )
}
