import { Toast } from '@byecode/ui'
import type { ErrorCode } from '@lighthouse/shared'
import { errorCodeMap, limitExceededCodeList } from '@lighthouse/shared'
import type { IsSuccessfulCall } from '@rpldy/shared'
import type { UploadyProps } from '@rpldy/shared-ui'

import http from '@/http'

const isSuccessfulCallToToast: IsSuccessfulCall = xhr => {
    if (xhr.status === 200) {
        const res = JSON.parse(xhr.response)
        if (res.code === '0030000') {
            return false
        }
        if (limitExceededCodeList.includes(res.code)) {
            Toast.error(`${errorCodeMap[res.code as ErrorCode]}`)
            return false
        }
    }
    return true
}

export const uploadAuth = (): Pick<UploadyProps, 'params' | 'headers'> => {
    return {
        headers: http.defaults.headers.common
    }
}

type UploadInDataSourceParams = {
    appId: string
    dsId: string
    fieldId: string
    recordId?: string
}

export const uploadInDataSourceManagerParams = (params: UploadInDataSourceParams): UploadyProps => {
    const { appId, dsId } = params
    return {
        destination: { url: `/lighthouse/api/v1/${appId}/oss/uploadInDataSource`, ...uploadAuth(), params: { dsId } },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}

type UploadVodInDataSourceParams = {
    appId: string
    dsId: string
    fieldId: string
    recordId?: string
}

/** 上传视频管理器使用 */
export const uploadVideoInDataSourceManagerParams = (params: UploadVodInDataSourceParams): UploadyProps => {
    const { appId, ...rest } = params
    return {
        destination: { url: `/lighthouse/api/v1/${appId}/oss/uploadVod`, ...uploadAuth(), params: rest },
        isSuccessfulCall: isSuccessfulCallToToast
    }
}
