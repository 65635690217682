import type { CascadeOption, CascadeValue } from '@byecode/ui'
import { Box, Button, CascadeList, Empty, Flex, IconFont, Input, MobileModal, Radio, Text } from '@byecode/ui'
import type { DrawerProps } from '@mantine/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'

import * as SC from './styles'

interface CascadeDrawerProps {
    opened: boolean
    isMultiple?: boolean
    leftIcon?: string
    options?: CascadeOption[]
    showFooterBtn?: boolean
    value: CascadeValue
    title: string
    isLastLevel?: boolean
    showPath?: boolean
    path?: string
    target: DrawerProps['target']
    onPathChange?: (v: CascadeOption) => void
    onClose: () => void
    onFinish?: (v: CascadeValue) => void
}

export const CascadeDrawer: React.FC<CascadeDrawerProps> = ({
    opened,
    leftIcon,
    isMultiple,
    showFooterBtn,
    options,
    title,
    value,
    showPath,
    target,
    isLastLevel,
    onClose,
    onPathChange,
    onFinish
}) => {
    const [selectValue, setSelectValue] = useState(value)
    const [selectOptions, setSelectOptions] = useState(options ?? [])
    const [keywords, setKeywords] = useState('')
    const [path, setPath] = useState('')
    const { t } = useTranslation()

    useUpdateEffect(() => {
        setSelectValue(value)
        value.length === 0 && setPath('')
    }, [value])

    useUpdateEffect(() => {
        setSelectOptions(options ?? [])
    }, [options])

    const flatOptions = useMemo(() => {
        function getChildOption(option: CascadeOption, list: CascadeOption[]) {
            const { value, label, path, labelPath, children = [] } = option
            let arrList = list
            for (const child of children) {
                const newChild = {
                    ...child,
                    path: `${path}/${child.value}`,
                    labelPath: `${labelPath}/${child.label}`,
                    isLast: (child.children ?? []).length === 0
                }
                arrList = [...arrList, newChild, ...getChildOption(newChild, [])]
            }
            return arrList
        }
        return (
            options?.reduce<CascadeOption[]>((pre, cur) => {
                const newOption = { ...cur, path: cur.value, labelPath: cur.label, isLast: (cur.children ?? []).length === 0 }
                return [...pre, newOption, ...getChildOption(newOption, [])]
            }, []) ?? []
        )
    }, [options])

    const valueOptionPath = useMemo(() => {
        const pathOption = flatOptions.find(option => option.path === path)
        if (pathOption) {
            return pathOption.labelPath
        }
        return selectValue
            .map(v => {
                const option = flatOptions.find(option => option.value === v)
                return showPath ? option?.labelPath : option?.label
            })
            .join(',')
    }, [selectValue, path, flatOptions, showPath])

    const handleClose = useCallback(() => {
        onClose()
        setKeywords('')
        setSelectOptions(options ?? [])
    }, [onClose, options])

    const handleFinish = useCallback(
        (v: CascadeValue) => {
            onFinish?.(v)
            handleClose()
        },
        [handleClose, onFinish]
    )

    const handleReset = useCallback(() => {
        handleFinish?.([])
        setKeywords('')
        setSelectOptions(options ?? [])
    }, [handleFinish, options])

    const handleBack = useCallback(() => {
        const parentPath = path.split('/').slice(0, -1).join('/')
        const parentOption = flatOptions.find(option => option.path === parentPath)
        setSelectOptions(parentOption?.children ?? options ?? [])
        setPath(parentPath)
    }, [flatOptions, options, path])

    const handleChange = useCallback(
        (v: CascadeValue) => {
            const newValue = v.reduce<string[]>((pre, cur) => {
                const isActive = selectValue.includes(cur)
                return isActive ? pre : [...pre, cur]
            }, [])
            setSelectValue(newValue)
            !showFooterBtn && onFinish?.(newValue)
        },
        [onFinish, selectValue, showFooterBtn]
    )

    const handleSearchValueChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const word = ev.target.value.trim()
            setKeywords(word)
            const newOptions = flatOptions.filter(option => option.label.includes(word) && (isLastLevel ? option.isLast : true))
            setSelectOptions(word === '' ? options ?? [] : newOptions)
        },
        [flatOptions, isLastLevel, options]
    )

    const handlePathChange = useCallback(
        (option: CascadeOption) => {
            const pathOption = flatOptions.find(v => v.value === option.value)
            if (pathOption) {
                const children = pathOption.children ?? []
                onPathChange?.(pathOption)
                setPath(pathOption.path ?? '')
                children.length > 0 && setSelectOptions(children)
                if (!showFooterBtn) {
                    children.length === 0 && handleClose()
                }
            }
        },
        [flatOptions, handleClose, onPathChange, showFooterBtn]
    )

    return (
        <MobileModal
            leftSlot={
                path.split('/').length > 1 && (
                    <Flex alignItems="center" onClick={handleBack}>
                        <IconFont type="ArrowLeftSmall" size={16} />
                    </Flex>
                )
            }
            data-ignore-click-away
            target={target}
            title={title}
            open={opened}
            closeOnClickOverlay
            styles={{
                modal: {
                    height: '50%'
                },
                body: {
                    backgroundColor: 'var(--color-gray-50)',
                    overflow: 'hidden'
                },
                close: {
                    display: 'none'
                }
            }}
            onClose={handleClose}
            extra={
                // 选中任意层级且有值才有完成按钮
                value.length > 0 && !isLastLevel ? (
                    <SC.FinishButton role="button" onClick={() => handleFinish(selectValue)}>
                        完成
                    </SC.FinishButton>
                ) : null
            }
        >
            <SC.Container>
                <SC.Cursor>
                    <SC.SearchInput
                        value={keywords}
                        placeholder={t('search')}
                        onChange={handleSearchValueChange}
                        prefix={<IconFont type="SearchLine" color="var(--color-gray-400)" size={16} />}
                        suffix={
                            keywords && (
                                <IconFont
                                    type="CloseCircle"
                                    color="var(--color-gray-400)"
                                    size={16}
                                    onClick={() => {
                                        setKeywords('')
                                        setSelectOptions(options ?? [])
                                    }}
                                />
                            )
                        }
                    />
                </SC.Cursor>
                <SC.Path>
                    <Text color="var(--color-gray-400)" size={14}>
                        已选：{valueOptionPath}
                    </Text>
                </SC.Path>
                {selectOptions.length === 0 ? (
                    <Empty description={t('noFindData')} icon="Nodata-8i554976" />
                ) : (
                    <Flex
                        direction="column"
                        style={{
                            flex: 1,
                            overflow: 'hidden',
                            gap: 12
                        }}
                    >
                        <Flex style={{ flex: 1, overflow: 'hidden' }}>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch'
                                }}
                            >
                                <CascadeList
                                    data={selectValue}
                                    lastLevel={isLastLevel}
                                    options={selectOptions}
                                    columnWidth="100%"
                                    styles={{
                                        root: {
                                            overflow: 'hidden',
                                            width: '100%'
                                            // height: '100%'
                                        },
                                        columns: {
                                            border: '1px solid var(--color-gray-200)',
                                            borderRadius: 8,
                                            height: '100%',
                                            flex: 'none',
                                            backgroundColor: 'var(--color-white)',
                                            overflow: 'hidden'
                                        },
                                        column: {
                                            borderStyle: 'none',
                                            flex: 'none',
                                            padding: 0
                                        },
                                        content: {
                                            height: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: 8
                                        },
                                        item: {
                                            height: '48px!important',
                                            backgroundColor: 'var(--color-white)',
                                            borderBottom: '1px solid var(--color-gray-200)',
                                            overflow: 'hidden',
                                            '&:last-child': {
                                                border: 'none'
                                            }
                                        },
                                        activeIcon: {
                                            color: 'var(----color-app-main)'
                                        }
                                    }}
                                    onChange={handleChange}
                                    onPathChange={handlePathChange}
                                    itemChildrenComponent={column => {
                                        const checked = selectValue.includes(column.value)
                                        return (
                                            <>
                                                <Flex alignItems="center" gap={8} style={{ flex: 1, overflow: 'hidden' }}>
                                                    {!isLastLevel && (
                                                        <Radio
                                                            checked={checked}
                                                            color={checked ? 'var(--color-app-main)' : 'transparent'}
                                                            styles={{
                                                                radio: {
                                                                    backgroundColor: 'var(--color-white)',
                                                                    borderRadius: '100%'
                                                                },
                                                                input: {
                                                                    width: '16px!important',
                                                                    height: '16px!important',
                                                                    borderWidth: checked ? 2 : 1,
                                                                    borderColor: `${
                                                                        checked ? 'var(--color-app-main)' : 'var(--color-theme-4)'
                                                                    }!important`
                                                                },
                                                                icon: {
                                                                    width: '8px!important',
                                                                    height: '8px!important',
                                                                    left: '4px!important',
                                                                    top: '4px!important'
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                    <Text
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                keywords === ''
                                                                    ? column.label
                                                                    : column.labelPath?.replaceAll(
                                                                          keywords,
                                                                          `<span style="color: var(--color-main);">${keywords}</span>`
                                                                      ) ?? ''
                                                        }}
                                                    />
                                                </Flex>
                                                <Flex alignItems="center" gap={4}>
                                                    {selectValue.includes(column.value) && (
                                                        <IconFont
                                                            onClick={e => {
                                                                e.stopPropagation()
                                                            }}
                                                            type="Tick"
                                                            size={16}
                                                            color="var(--color-app-main)"
                                                        />
                                                    )}
                                                    {(column.children ?? []).length > 0 && (
                                                        <IconFont
                                                            onClick={e => {
                                                                e.stopPropagation()
                                                                handlePathChange(column)
                                                            }}
                                                            type="ArrowRightSmall"
                                                            size={16}
                                                            color="var(--color-gray-400)"
                                                        />
                                                    )}
                                                </Flex>
                                            </>
                                        )
                                    }}
                                />
                            </div>
                        </Flex>
                        {showFooterBtn && (
                            <Flex gap={12} style={{ padding: '10px 0' }}>
                                <Button size="lg" block onClick={handleReset}>
                                    清除
                                </Button>
                                <Button
                                    size="lg"
                                    block
                                    type="primary"
                                    style={{ backgroundColor: 'var(--color-app-main)' }}
                                    onClick={() => handleFinish(selectValue)}
                                >
                                    确定
                                </Button>
                            </Flex>
                        )}
                    </Flex>
                )}
            </SC.Container>
        </MobileModal>
    )
}
