import { IconFont, tinyButtons, useContextPopoverHeight, usePopoverContext } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { type DataSourceAbstract, type Field, type TableColumn, type ViewFieldProps, DataSourceType } from '@lighthouse/core'
import { arrayMove } from '@lighthouse/tools'
import { find, findIndex } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { FieldTypeColorMap, signFieldIconColor, signFieldId } from '../../constants'
import { getFieldIcon, getIsAppointField } from '../../utils'
import { FieldListItem } from '../FieldListItem'
import { FieldTypeTag } from '../FieldTypeTag'
import { List } from '../List'
import type { InsertDirection } from '../Table'
import { MoreSetting } from './MoreSetting'

const MAX_HEIGHT = 600
interface ListItemData {
    label: string
    value: string
    visible?: boolean
    width: number
    icon: string
}
interface DisplayProps {
    children?: React.ReactNode
    columns: ViewFieldProps[]
    dataSource?: DataSourceAbstract
    configurable?: boolean
    color?: string
    dataSourceList?: DataSourceAbstract[]
    disabled?: boolean
    disabledShowInnerType?: boolean
    disableIconColor?: boolean
    onChange?: (data: TableColumn[]) => void
    onUpdateField?: (fieldId: string, data: Field) => Promise<boolean>
    onCreateField?: (data: Field, sourceId: string, direction: InsertDirection) => Promise<boolean>
    onDeleteField?: (data: Field) => Promise<boolean>
}

const IconBox = styled.div`
    justify-content: center;
    align-items: center;
    padding: 0 4px;
    width: 28px;
    display: none;
`

const SCxEye = styled(IconFont)<{ disable?: boolean }>`
    cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
`

const SCxBox = styled.div`
    display: flex;
    align-items: center;
`

const PropertySettingWrapper = styled.div`
    padding: 4px 0;
    width: 100%;
    min-width: 220px;
    flex: 1;
    overflow-y: auto;
    ${tinyButtons}
`

const Extra = styled.div<{ width?: number }>`
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
`

const QuickGroup = styled.div`
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
`

const GroupTitle = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`

const GroupButton = styled.div<{ color?: string; disabled?: boolean }>`
    color: ${({ color }) => color || 'var(--color-main)'};
    font-size: var(--font-size-sm);

    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

// const SCxSwitch = styled(Switch)<{ color?: string }>`
//     display: flex;
//     align-items: center;
//     .mantine-Switch-input:checked + .mantine-Switch-track {
//         background-color: ${({ color }) => color};
//         border-color: ${({ color }) => color};
//     }
// `

export const ViewFieldDisplayConfigure: React.FC<DisplayProps> = ({
    columns,
    dataSource,
    configurable = false,
    color = 'var(--color-main)',
    dataSourceList,
    disabled,
    disabledShowInnerType,
    disableIconColor,
    onChange,
    onUpdateField,
    onCreateField,
    onDeleteField
}) => {
    const { context } = usePopoverContext()
    const [maxHeight, internalRef] = useContextPopoverHeight({ context })
    const [showSchemaList, hideSchemaList] = useMemo(() => {
        const showList: ListItemData[] = []
        const hideList: ListItemData[] = []
        columns.forEach(item => {
            const { fieldId, visible, width = 160, type, title, innerType } = item
            const icon = getFieldIcon(fieldId, type, innerType)
            if (visible) {
                showList.push({
                    value: fieldId,
                    label: title,
                    icon,
                    visible,
                    width
                })
                return
            }
            hideList.push({
                value: fieldId,
                label: title,
                icon,
                visible,
                width
            })
        })
        return [showList, hideList]
    }, [columns])

    const isJoinDataSource = dataSource?.type === DataSourceType.joinDataSource

    const handleSwitchChange = useCallback(
        (checked: boolean, id: string) => {
            if (disabled) {
                return
            }
            const newTableProps = columns.map(item => ({
                id: item.fieldId,
                width: item.width || 160,
                visible: item.visible
            }))
            const col = find(({ id: colId }) => colId === id, newTableProps)

            if (!col) {
                return
            }
            const filterTableProps = newTableProps.filter(item => item.id !== id)
            const t = [...filterTableProps, { ...col, visible: checked }]
            onChange?.(t)
        },
        [columns, disabled, onChange]
    )

    const toggleAllDisplay = useCallback(
        (visible = false) => {
            if (disabled) {
                return
            }
            if (!visible) {
                const bool = true
                const firstShowColumns = columns.find(item => item.visible)
                if (!firstShowColumns) {
                    return
                }
                const firstShowTableProps = {
                    id: firstShowColumns.fieldId,
                    width: firstShowColumns.width || 160,
                    visible: firstShowColumns.visible
                }
                const [showTableProps, hideTableProps] = columns.reduce<[TableColumn[], TableColumn[]]>(
                    (list, cur) => {
                        //   return  item.visible
                        if (cur.visible && cur.fieldId !== firstShowColumns?.fieldId) {
                            list[0].push({
                                id: cur.fieldId,
                                width: cur.width || 160,
                                visible: false
                            })
                        }
                        if (!cur.visible) {
                            list[1].push({
                                id: cur.fieldId,
                                width: cur.width || 160,
                                visible: cur.visible
                            })
                        }
                        return list
                    },
                    [[], []]
                )
                onChange?.([firstShowTableProps, ...hideTableProps, ...showTableProps])
                return
            }
            const list = [...showSchemaList, ...hideSchemaList]
            const newTableProps = list.reduce<TableColumn[]>((list, cur) => {
                const column = find(c => c.fieldId === cur.value, columns)
                if (column) {
                    list.push({
                        ...column,
                        id: column.fieldId,
                        width: column?.width || 160,
                        visible: true
                    })
                }
                return list
            }, [])
            onChange?.(newTableProps)
        },
        [columns, disabled, hideSchemaList, onChange, showSchemaList]
    )

    const handleSortEnd = useCallback(
        (ev: DragEndEvent) => {
            const {
                active: { id: activeId },
                over
            } = ev
            const overId = over?.id
            if (!overId) {
                return
            }
            const list = [...showSchemaList, ...hideSchemaList]
            const sourceIndex = findIndex(col => col.value === activeId, list)
            const targetIndex = findIndex(col => col.value === overId, list)
            // const tempProps = map(({ value, visible = true, width }) => ({ id: value, visible, width }), schemaList)
            const newList = arrayMove(list, sourceIndex, targetIndex)
            const newTableProps = newList.map(item => {
                const { value, visible = true, width } = item
                return { id: value, visible, width }
            })
            onChange?.(newTableProps)
        },
        [showSchemaList, hideSchemaList, onChange]
    )

    const showLength = useMemo(() => showSchemaList.length, [showSchemaList.length])

    const listStyle: React.CSSProperties = { maxHeight: 'none', overflow: 'visible' }

    // const realPrimaryDataSourceId = useMemo(() => {
    //     if (!dataSource) {
    //         return undefined
    //     }
    //     return dataSource.viewOptions.joinConfig?.primaryDsId
    // }, [dataSource])

    const getIsSignField = useCallback((config: ViewFieldProps) => {
        return getIsAppointField(config.fieldId, signFieldId)
    }, [])

    const itemRenderer: React.FC<{ value: string; label: string; icon: string; sortable?: boolean }> = useCallback(
        item => {
            const { icon, label, value, sortable } = item
            const config = find(TableItem => TableItem.fieldId === value, columns)
            if (!config) {
                return null
            }
            const isSignField = getIsSignField(config)
            const iconColor = disableIconColor ? 'var(--color-gray-400)' : isSignField ? signFieldIconColor : FieldTypeColorMap[config.type]
            const { visible, fieldId, dsName, dsId, innerType } = config
            const isDisable = !!sortable && showLength === 1
            // const isShowDsName = dsId !== dataSource?.id

            return (
                <FieldListItem
                    icon={icon}
                    iconColor={iconColor}
                    title={label}
                    description={isJoinDataSource ? dsName : ''}
                    suffix={
                        <Extra width={configurable ? 56 : 32}>
                            <SCxBox>
                                <IconBox className="field-visible">
                                    {visible ? (
                                        <SCxEye type="Hide" size={16} disable={isDisable} onClick={e => handleSwitchChange(false, value)} />
                                    ) : (
                                        <IconFont type="Eye" size={16} onClick={e => handleSwitchChange(true, value)} />
                                    )}
                                </IconBox>
                                {!disabledShowInnerType && <FieldTypeTag className="field-tag" type={config.type} innerType={innerType} />}
                            </SCxBox>

                            {configurable && dataSource && (
                                <MoreSetting
                                    columns={columns}
                                    dataSource={dataSource}
                                    fieldId={fieldId}
                                    dataSourceList={dataSourceList}
                                    disableHidden
                                    onUpdateField={onUpdateField}
                                    onCreateField={onCreateField}
                                    onDeleteField={onDeleteField}
                                />
                            )}
                            {/* <SCxSwitch
                                disabled={isDisable}
                                checked={visible}
                                color={color}
                                onChange={ev => {
                                    handleSwitchChange(ev.target.checked, value)
                                }}
                            /> */}
                        </Extra>
                    }
                    sortable={sortable}
                />
            )
        },
        [
            columns,
            configurable,
            dataSource,
            dataSourceList,
            disableIconColor,
            disabledShowInnerType,
            getIsSignField,
            handleSwitchChange,
            isJoinDataSource,
            onCreateField,
            onDeleteField,
            onUpdateField,
            showLength
        ]
    )

    return (
        <PropertySettingWrapper ref={internalRef} style={{ maxHeight }}>
            <QuickGroup>
                <GroupTitle>显示字段</GroupTitle>
                <GroupButton
                    color={color}
                    disabled={disabled}
                    onClick={() => {
                        toggleAllDisplay()
                    }}
                >
                    全部隐藏
                </GroupButton>
            </QuickGroup>
            <List
                sortable
                disabled={disabled}
                data={showSchemaList}
                onSortEnd={handleSortEnd}
                style={listStyle}
                itemDraggerStyle={{ height: isJoinDataSource ? 20 : 24 }}
                itemStyle={{
                    alignItems: 'flex-start'
                }}
                itemRender={rest => itemRenderer({ ...rest, sortable: true })}
            />
            {hideSchemaList.length > 0 && (
                <QuickGroup>
                    <GroupTitle>未显示字段</GroupTitle>
                    <GroupButton
                        disabled={disabled}
                        color={color}
                        onClick={() => {
                            toggleAllDisplay(true)
                        }}
                    >
                        全部显示
                    </GroupButton>
                </QuickGroup>
            )}
            <List data={hideSchemaList} style={listStyle} onSortEnd={handleSortEnd} itemRender={itemRenderer} />
        </PropertySettingWrapper>
    )
}
