import type {
    ApplicationSettingLanguage,
    DataSourceAbstract,
    FieldBlockAbstract,
    FieldInputADTValue,
    FieldInputValue,
    FieldType,
    RecordLikeProtocol
} from '@lighthouse/core'
import type { ApplicationPreviewEnum, CascadeParam, CheckBoxParam, FieldInputError, PersonParam, PhoneNumberParam, RelativeFieldParam, UseUploadFileSParameter } from '@lighthouse/shared'
import { FieldInput } from '@lighthouse/shared'
import type { UploadOptions, UploadyProps } from '@rpldy/uploady'
import React, { useMemo } from 'react'
import styled from 'styled-components'

export interface FieldBlockProps extends RelativeFieldParam, CascadeParam, PhoneNumberParam, PersonParam, CheckBoxParam {
    blockData: FieldBlockAbstract
    // field?: Field
    blockType: 'field' | 'form'
    value?: FieldInputValue
    previewType?: ApplicationPreviewEnum
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    videoUploadyOption: Pick<UseUploadFileSParameter, 'info' | 'options'>
    type?: FieldType
    language: string
    dataSource?: DataSourceAbstract
    record?: RecordLikeProtocol
    richTextUploadOptions?: UploadyProps
    isValid: boolean
    error?: FieldInputError

    onChange?: (fieldValue: FieldInputADTValue) => void
    onSaveChange?: (fieldValue: FieldInputADTValue) => void
}

const SCxContainer = styled.div`
    width: 100%;
    /* overflow: hidden; */
    /* padding: 6px 5px 4px 4px; */
`

const FieldBlock: React.FC<FieldBlockProps> = props => {
    const { blockData, richTextUploadOptions, uploadyOptions, previewType, blockType, dataSource, ...rest } = props
    const { config, title, type } = blockData

    return (
        <SCxContainer data-stop-action-propagation>
            <FieldInput
                config={{
                    ...config,
                    richTextUploadOptions,
                    uploadyOptions,
                    title,
                    previewType
                }}
                mode="datasource"
                dataSource={dataSource}
                {...rest}
            />
        </SCxContainer>
    )
}

export default FieldBlock
