import type { FilterBlockAbstract, FilterBlockValue } from '@lighthouse/core'
import { ApplicationPreviewEnum } from '@lighthouse/shared'
import { useBreakpoint } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { DesktopFilter } from './DesktopFilter'
import { MobileFilter } from './MobileFilter'
import type { FilterItemProps } from './types'

export interface FilterBlockProps
    extends Pick<FilterItemProps, 'recordId' | 'dataSourceList' | 'onChange' | 'onFetchTextOptions' | 'onFetchCascadeOptions'> {
    blockData: FilterBlockAbstract
    filterData: FilterBlockValue
    previewType: ApplicationPreviewEnum
}

const SCxFilterBlockContainer = styled.div`
    width: 100%;
    min-height: 32px;
    padding: var(--block-padding);
    /* overflow: hidden; */
    /* padding: 6px 5px 4px 4px; */
`

const FilterBlock: React.FC<FilterBlockProps> = ({
    dataSourceList,
    blockData,
    filterData,
    previewType,
    recordId,
    onChange,
    onFetchTextOptions,
    onFetchCascadeOptions
}) => {
    const { ref, width } = useBreakpoint()
    const { id: blockID, config } = blockData
    const isMobile = previewType === ApplicationPreviewEnum.mobile
    const { filterItems, mode, labelWidth } = config
    const Component = useMemo(() => (isMobile ? MobileFilter : DesktopFilter), [isMobile])

    return (
        <SCxFilterBlockContainer id={`block-${blockID}`} data-block-type="chartView" ref={ref}>
            <Component
                list={filterItems}
                mode={mode}
                labelWidth={labelWidth}
                filterData={filterData}
                dataSourceList={dataSourceList}
                recordId={recordId}
                onFetchTextOptions={onFetchTextOptions}
                onFetchCascadeOptions={onFetchCascadeOptions}
                onChange={onChange}
            />
        </SCxFilterBlockContainer>
    )
}

export default FilterBlock
