import type { Field } from '@lighthouse/core'
import { DateFieldRange, VariableType } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import type { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { filterModeIndex, propertyMode } from '../../constants/filter'
import { useApplicationContext, useDataSourceContext } from '../../contexts'
import { getDateConfig, isCheckboxValue, isIdsValue, isNumberValue, isTextValue } from '../../utils'
import { AggregationInnerTypeToFieldType, DEFAULT_FILTER_VALUE_VARIABLE } from '../'
import { PersonSelect } from '../PersonSelect'
import { BoolInput, BoolItem } from './BoolItem'
import { boolOption } from './constants'
import * as SC from './styles'

interface ParamsProps {
    field?: Field
    prefixName: string
    width?: string
    focusOutLine?: string
    enableCurrentUser?: boolean
    disabled?: boolean
    // cascadeOptions: CascadeSelectOption[]
}

export const Params: React.FC<ParamsProps> = ({ field, prefixName, focusOutLine, width = 'auto', enableCurrentUser = true, disabled }) => {
    console.log('🚀 ~ file: Params.tsx ~ line 27 ~ focusOutLine', focusOutLine)
    const { control } = useFormContext()
    const { personOptions: DsPersonOptions } = useDataSourceContext()
    const { personOptions: appPersonOptions } = useApplicationContext()

    const personOptions = useMemo(() => {
        return DsPersonOptions || appPersonOptions || []
    }, [DsPersonOptions, appPersonOptions])

    const operator: string = useWatch({
        control,
        name: `${prefixName}.operator`
    })

    const realField = useMemo(() => {
        if (!field) {
            return
        }
        if ((field.type === 'formula' || field.type === 'aggregation') && field.innerType) {
            return {
                ...field,
                type: AggregationInnerTypeToFieldType[field.innerType]
            } as Field
        }
        return field
    }, [field])

    const options = useMemo(() => {
        if (!field) {
            return []
        }
        if (field.type === 'select' || field.type === 'selectGenerationByText') {
            const options = field.type === 'select' ? field.select.options : field.selectGenerationByText.options
            return options.map(item => ({
                label: item.label,
                value: item.label,
                color: item?.color
            }))
        }
        return []
    }, [field])

    const mode = useMemo(() => {
        const index = filterModeIndex[operator]
        if (!realField) {
            return 'input'
        }
        if (!index && index !== 0) {
            return
        }
        if (!realField.type) {
            return
        }
        return propertyMode?.[realField.type]?.[index]
    }, [operator, realField])

    // const showTime = useMemo(() => {
    //     if (field?.type === 'formula' || field?.type === 'aggregation') {
    //         return true
    //     }

    //     return realField?.type === 'date' && !!realField?.date?.format && realField.date.format.includes('HH')
    // }, [field, realField])

    // const format = useMemo(() => {
    //     if (field?.type === 'date') {
    //         return field.date?.format || defaultDateFormat
    //     }
    // }, [field])

    const renderParams = useCallback(
        (ctlField: ControllerRenderProps<FieldValues, `${string}.paramList`>) => {
            const [firstParam = DEFAULT_FILTER_VALUE_VARIABLE, secondParam = DEFAULT_FILTER_VALUE_VARIABLE] = ctlField.value || [
                DEFAULT_FILTER_VALUE_VARIABLE,
                DEFAULT_FILTER_VALUE_VARIABLE
            ]

            const firstValue = firstParam?.valueVariable?.value
            const secondValue = secondParam?.valueVariable?.value

            switch (mode) {
                case 'date': {
                    const dateValue = isNumberValue(firstValue) ? new Date(Number(firstValue)) : undefined
                    const dateInfo = getDateConfig(field)
                    const range = dateInfo?.range
                    const format = dateInfo?.format
                    const calendarMode = range === DateFieldRange.MONTH ? 'month' : 'day'
                    const showTime = range === DateFieldRange.MINUTE
                    return (
                        <SC.ParamsDatePicker
                            clearable
                            key={`${prefixName}.paramList`}
                            {...ctlField}
                            // disablePortal
                            popoverProps={{
                                withinPortal: true
                            }}
                            disabled={!realField || disabled}
                            style={{ width: '100%' }}
                            showTime={showTime}
                            mode={calendarMode}
                            format={format}
                            value={dateValue}
                            placeholder="请选择"
                            onChange={val => {
                                if (!val) {
                                    return ctlField.onChange([DEFAULT_FILTER_VALUE_VARIABLE])
                                }
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: val.valueOf() }
                                    }
                                ])
                            }}
                        />
                    )
                }
                case 'rangeDate': {
                    const firstDateValue = isNumberValue(firstValue) ? new Date(Number(firstValue)) : undefined
                    const secondDateValue = isNumberValue(secondValue) ? new Date(Number(secondValue)) : undefined
                    const dateInfo = getDateConfig(field)
                    const range = dateInfo?.range
                    const format = dateInfo?.format
                    const calendarMode = range === DateFieldRange.MONTH ? 'month' : 'day'
                    const showTime = range === DateFieldRange.MINUTE
                    return (
                        <>
                            <SC.ParamsDatePicker
                                clearable
                                key={`${prefixName}.paramList`}
                                {...ctlField}
                                popoverProps={{
                                    withinPortal: true
                                }}
                                disabled={!realField || disabled}
                                showTime={showTime}
                                format={format}
                                theme={focusOutLine}
                                mode={calendarMode}
                                value={firstDateValue}
                                placeholder="请选择"
                                onChange={val => {
                                    if (!val) {
                                        return ctlField.onChange([DEFAULT_FILTER_VALUE_VARIABLE, secondParam])
                                    }
                                    ctlField.onChange([
                                        { type: VariableType.VALUE, valueVariable: { type: realField?.type, value: val.valueOf() } },
                                        secondParam
                                    ])
                                }}
                            />
                            <SC.ParamsDatePicker
                                clearable
                                key={`${prefixName}.paramList`}
                                {...ctlField}
                                popoverProps={{
                                    withinPortal: true
                                }}
                                disabled={!realField || disabled}
                                showTime={showTime}
                                format={format}
                                mode={calendarMode}
                                // disablePortal
                                value={secondDateValue}
                                placeholder="请选择"
                                // style={{ width }}
                                onChange={val => {
                                    if (!val) {
                                        return ctlField.onChange([firstParam, DEFAULT_FILTER_VALUE_VARIABLE])
                                    }
                                    ctlField.onChange([
                                        firstParam,
                                        { type: VariableType.VALUE, valueVariable: { type: realField?.type, value: val.valueOf() } }
                                    ])
                                }}
                            />
                        </>
                    )
                }
                case 'multiPerson':
                case 'person': {
                    const personValue = isIdsValue(firstValue) ? firstValue : []
                    return (
                        <PersonSelect
                            options={personOptions}
                            enableCurrentUser={enableCurrentUser}
                            color={focusOutLine}
                            isMultiple={mode === 'multiPerson'}
                            tagClearable
                            placeholder="请选择"
                            focusOutLine={focusOutLine}
                            width={width}
                            disabled={!realField || disabled}
                            // minWidth={width}
                            styles={{ container: { height: 32 } }}
                            isHiddenUserId
                            value={personValue}
                            onChange={val => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: val }
                                    }
                                ])
                            }}
                        />
                    )
                }

                case 'select': {
                    const selectValue = isIdsValue(firstValue) ? firstValue : []
                    return (
                        <SC.ParamsSelect
                            clearable
                            key={`${prefixName}.paramList`}
                            style={{ width }}
                            value={selectValue?.[0] || ''}
                            styles={{
                                root: {
                                    width: '100%'
                                }
                            }}
                            disabled={!realField || disabled}
                            placeholder="请选择"
                            onChange={val => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: field?.type, value: val ? [val] : [] }
                                    }
                                ])
                            }}
                            options={options}
                        />
                    )
                }

                case 'multiSelect': {
                    const selectValue = isIdsValue(firstValue) ? firstValue : []
                    return (
                        <SC.ParamsMultiSelect
                            clearable
                            key={`${prefixName}.paramList`}
                            style={{ width, minWidth: 160}}
                            placeholder="请选择"
                            overlayStyle={{ maxHeight: 300, overflowY: 'auto'}}
                            themeColor={focusOutLine}
                            downIcon="CaretDown"
                            disabled={!realField || disabled}
                            value={selectValue}
                            onChange={val => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: val }
                                    }
                                ])
                            }}
                            onClear={() => ctlField.onChange([])}
                            options={options}
                        />
                    )
                }

                case 'checkbox': {
                    const selectValue = isCheckboxValue(firstValue) ? (firstValue ? '1' : '0') : ''
                    return (
                        <SC.ParamsSelect
                            clearable
                            disabled={!realField || disabled}
                            key={`${prefixName}.paramList`}
                            style={{ width }}
                            optionComponent={BoolItem}
                            customInputValueRender={BoolInput}
                            styles={{
                                root: {
                                    width: '100%',
                                    height: '32px'
                                }
                            }}
                            value={selectValue}
                            onChange={val => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: val === '' ? undefined : !!Number(val) }
                                    }
                                ])
                            }}
                            options={boolOption}
                        />
                    )
                }

                default: {
                    const inputType = ['NUMBER'].includes(realField?.innerType || '') ? 'number' : 'text'
                    const originValue = isTextValue(firstValue) ? firstValue : ''
                    return (
                        <SC.ParamsInput
                            key={`${prefixName}.paramList`}
                            width={width}
                            {...ctlField}
                            disabled={!realField || disabled}
                            placeholder="请输入"
                            value={originValue}
                            type={inputType}
                            onChange={ev => {
                                const { value: newValue } = ev.target
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: { type: realField?.type, value: newValue }
                                    }
                                ])
                            }}
                        />
                    )
                }
            }
        },
        [mode, field, prefixName, realField, disabled, personOptions, enableCurrentUser, focusOutLine, width, options]
    )

    return (
        <Controller
            name={`${prefixName}.paramList`}
            control={control}
            render={({ field: ctlField }) => {
                const dummyComp = <div />
                if (!mode || operator === 'isEmpty' || operator === 'isNotEmpty') {
                    return dummyComp
                }
                // const timestamp = Number(ctlField.value)
                return <SC.Box key={`${prefixName}.paramList`}>{renderParams(ctlField)}</SC.Box>
            }}
        />
    )
}
