import { createStyles } from '@byecode/ui/theme/createStyles'
import { css } from '@byecode/ui/theme/stitches.config'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef } from 'react'

import { Box } from '../Box'

const useStyles = createStyles(() => {
    return {
        root: css({
            padding: '8px 6.5px',
            color: 'var(--color-black)',
            backgroundColor: '$colorGray100',
            outline: 'none',
            border: '1.5px solid transparent',
            fontSize: '14px',
            lineHeight: '22px',
            minHeight: 48,
            borderRadius: 6,
            prettyScroll: true,
            resize: 'none',
            '&:focus, &:focus-within': {
                backgroundColor: '$colorWhite',
                borderColor: '$colorPrimary'
            },
            '&:hover': {
                backgroundColor: '$colorGray200'
            },

            '&::placeholder': {
                fontSize: '14px',
                color: '$colorGray400'
            }
        })
    }
})

export interface TextAreaProps extends StyleComponentProps<Selectors<typeof useStyles>>, React.ComponentPropsWithoutRef<'textarea'> {}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>((props, ref) => {
    const { className, classNames, styles, unstyled, ...rest } = props
    const { classes } = useStyles({}, { name: 'textarea', classNames, styles, unstyled })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return <Box ref={ref} component="textarea" className={clsx(className, classes.root)} {...rest} />
})
