import { Button, Flex, IconFont } from '@byecode/ui'
import type { SubFormColumn } from '@lighthouse/core'
import { FieldInput, TagIcon } from '@lighthouse/shared'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { CMxEmpty, CMxEndIndex, CMxFirstIndex, CMxRecord } from './styles'
import { SubFormCell } from './SubFormCell'
import { useSubFormFieldInputContext } from './SubFormFieldInputProvider'
import type { SubFormRecord } from './types'

interface SubFormRecordProps {
    columns: SubFormColumn[]
    data: SubFormRecord
    index: number
}

export const SubFormTableRecord: React.FunctionComponent<SubFormRecordProps> = ({ columns, data, index }) => {
    const recordProps = useSubFormFieldInputContext()

    return (
        <CMxRecord style={{ width: columns.length === 0 ? '100%' : 'max-content' }}>
            <CMxFirstIndex>{index + 1}</CMxFirstIndex>
            {columns.map(column => (
                <SubFormCell key={column.id} recordId={data.id} value={data.content?.[column.id]} column={column} />
            ))}
            {columns.length === 0 && <CMxEmpty />}
            <CMxEndIndex style={{ backgroundColor: 'var(--color-white)' }}>
                <TagIcon
                    icon="Duplicate"
                    color="var(--color-gray-400)"
                    size={24}
                    iconSize={16}
                    onClick={() => recordProps.onRecordRemove?.(data.id)}
                    enableHover
                    hoverBackground="var(--color-gray-100)"
                    hoverColor="var(--color-black)"
                    radius={4}
                    style={{ flexShrink: 0 }}
                />
                <TagIcon
                    icon="Trash"
                    color="var(--color-gray-400)"
                    size={24}
                    iconSize={16}
                    onClick={() => recordProps.onRecordRemove?.(data.id)}
                    enableHover
                    hoverBackground="var(--color-gray-100)"
                    hoverColor="var(--color-black)"
                    radius={4}
                    style={{ flexShrink: 0 }}
                />
            </CMxEndIndex>
        </CMxRecord>
    )
}
