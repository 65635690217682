import type { AlignMent, ButtonAction, ButtonBlockAbstract, RichTextContentProtocol } from '@lighthouse/core'
import { ActionButton, useActionRunningLoadings } from '@lighthouse/shared'
import React from 'react'
import styled from 'styled-components'

interface ButtonBlockProps {
    blockData: ButtonBlockAbstract
    onActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
    onRenderTitle: (v: RichTextContentProtocol) => string
    // onRenderButtonTitle: (v: RichTextContentProtocol) => string
}

const SCxContainer = styled.div<Pick<React.CSSProperties, 'justifyContent'>>`
    width: 100%;
    padding: var(--block-padding);
    display: flex;
    gap: 8px;
    justify-content: ${({ justifyContent }) => justifyContent};
`
const buttonAlginMap: Record<AlignMent, string> = {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end'
}

const ButtonBlock: React.FC<ButtonBlockProps> = ({ blockData, onActionTrigger, onRenderTitle }) => {
    const { config } = blockData
    const { list = [], radius = '0', align = 'left', ...rest } = config

    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

    return (
        <SCxContainer justifyContent={buttonAlginMap[align]}>
            {list.map(item => {
                const { action, id } = item
                return (
                    <ActionButton
                        key={id}
                        {...rest}
                        radius={rest.shape === 'rectangle' ? radius : '100'}
                        config={item}
                        // loading={loadings[id]}
                        disabled={loadings[id]}
                        data-stop-action-propagation
                        onClick={e => handleActionTriggerWithLoading({ type: 'click', action, id, trigger: onActionTrigger })}
                        onMouseOver={e => handleActionTriggerWithLoading({ type: 'hover', action, id, trigger: onActionTrigger })}
                        onRenderTitle={onRenderTitle}
                    />
                )
            })}
        </SCxContainer>
    )
}

export default ButtonBlock
