import type {
    FilterFormType,
    RecordOpenType,
    RichTextContentProtocol,
    Sorter,
    StripeAction,
    TypeInstanceMap,
    VariableADTvalue,
    WeChatPayAction,
    WechatTemplateMsgAction
} from '@lighthouse/core'
import type { AnyObject } from 'immer/dist/internal'
import type { Edge } from 'reactflow'
import type { MakeADT } from 'ts-adt/MakeADT'

import type { TiptapEditorJSONContent } from '../components/RichText'
import type { ApproverUser, EmailParticipant, SenderAccountType } from './common'

export type FieldPermission = {
    fieldId: string
    mode: 'READ' | 'WRITE' | 'NONE'
}

export enum FlowSelectSourceType {
    UPSTREAM = 'UPSTREAM',
    DATASOURCE = 'DATASOURCE'
}

export enum LoopTypeEnum {
    BASE_ON_TIMES = 'BASE_ON_TIMES',
    BASE_ON_RECORDS = 'BASE_ON_RECORDS'
}

export enum FlowSelectRecordType {
    UPSTREAM = 'UPSTREAM',
    // SELECT_RECORD = 'SELECT_RECORD'
    CURRENT_RECORD = 'CURRENT_RECORD'
}

export enum OpenDetailPageSelectedRecordType {
    CURRENT_RECORD = 'CURRENT_RECORD',
    MATCHED_RECORD = 'MATCHED_RECORD',
    UPSTREAM_RECORD = 'UPSTREAM_RECORD'
}

export type TriggerNodeType =
    | 'CREATE_RECORD_TRIGGER'
    | 'UPDATE_RECORD_TRIGGER'
    | 'MATCH_CONDITION_TRIGGER'
    | 'SCHEDULED_TRIGGER'
    | 'CLICK_TRIGGER'
    | 'MANUALLY_TRIGGER'
    | 'INITIATE_APPROVE_TRIGGER'
    | 'ARG_TRIGGER'
export type OtherPlatformType = 'WECHAT_COMPANY' | 'DINGTALK' | 'I_CLICK' | 'ALIYUN' | 'WECHAT' | 'STRIPE'
export type OtherPlatformNodeType =
    | 'WX_ROBOT_ACTION'
    | 'DINGTALK_ROBOT_ACTION'
    | 'I_CLICK_CONTACT_ACTION'
    | 'ALIYUN_MESSAGE_ACTION'
    | 'WECHAT_TEMPLATE_MSG_ACTION'
    | 'STRIPE'
export type NoticeNodeType = 'SEND_EMAIL_ACTION' | 'DINGTALK_ROBOT_ACTION' | 'STATION_MESSAGE_ACTION'
export type OperatorNodeType =
    | 'OPEN_PAGE'
    | 'OPEN_LINK'
    | 'OPEN_FORM_PAGE'
    | 'OPEN_RECORD_PAGE'
    | 'OPEN_RECORD_EDIT_PAGE'
    | 'GO_BACK'
    | 'NOTIFICATION'
    | 'COPY'
    | 'DELETE_RECORD'
    | 'SHARE_TO_WECHAT'
    | 'DOWNLOAD_FILE'
    | 'CALL'
    | 'WECHAT_PAY'
    | 'REFRESH_PAGE'
export type ApproveNodeType = 'APPROVE_ACTION' | 'CARBON_COPY_ACTION'
export type ActionNodeType =
    | 'CREATE_SINGLE_RECORD_ACTION'
    | 'CREATE_RECORD_ACTION'
    | 'UPDATE_RECORD_ACTION'
    | 'FIND_SINGLE_RECORD_ACTION'
    | 'FIND_RECORD_ACTION'
    | 'INITIATE_APPROVE_ACTION'
    | 'LOOP_ACTION'
    | OtherPlatformNodeType
    | ApproveNodeType
    | OperatorNodeType
    | NoticeNodeType
export type ConditionNodeType = 'CONDITION' | 'INITIATE_APPROVE_ACTION'
export type EndNodeType = 'END'

export type NodeTypes = TriggerNodeType | ActionNodeType | ConditionNodeType | EndNodeType | OtherPlatformNodeType | ApproveNodeType

export type GeneratedDataNodeTypes =
    | 'CREATE_RECORD_TRIGGER'
    | 'UPDATE_RECORD_TRIGGER'
    | 'MATCH_CONDITION_TRIGGER'
    | 'CREATE_SINGLE_RECORD_ACTION'

// --------- config -------
export type DrawMode = 'nodeConfigurator' | 'nodeCreator'

export type NodeOption = {
    value: NodeTypes
    label: string
    icon: string
    color?: string
    describe: string
}

export type OtherPlatformOption = {
    value: OtherPlatformType
    label: string
    icon: string
    color?: string
    describe: string
    children: NodeOption[]
}

export type NodeFieldContent = {
    // 需要设置的字段
    fieldId: string
    value: {
        content: TiptapEditorJSONContent[] // 富文本格式
        refValue: {
            // 引用节点的配置
            nodeId: string // 引用自哪个节点
            fieldId: string // 引用的字段id
        }
    }
}

export type DataSourceRelayConfig = {
    dataSourceId: string
}

export type CreateRecordTriggerConfig = DataSourceRelayConfig

export type UpdateRecordTriggerConfig = DataSourceRelayConfig & {
    watchedFields: string[]
}

export type MatchConditionTriggerConfig = DataSourceRelayConfig & {
    filter: FilterFormType
}

export type InitiateApproveTriggerConfig = DataSourceRelayConfig & {
    stateFieldId: string // 绑定的状态字段id,
    pageId: string // 表单所在的页面id
    formModuleId: string // 表单容器 Id
}

export type SubProcessVariable = {
    id: string
    name: string
    innerType: TypeInstanceMap
}

// 子流程触发节点
export type VariableTriggerConfig = {
    args: SubProcessVariable[]
}

export type ScheduledTriggerRule = 'ONCE' | 'MINUTE' | 'HOUR' | 'DAY' | 'WEEKDAY' | 'WEEK' | 'MONTH'

export type ClickTriggerConfig = DataSourceRelayConfig

export type ScheduledTriggerConfig = {
    scheduleStartTime: Date
    scheduleEndTime: Date
    scheduleRule: ScheduledTriggerRule
    /** 只触发一次，指定时间 */
    onceTime?: Date
    /** 分规则 1~60 */
    scheduleMinuteRule?: string
    /** 时规则 1~24 */
    scheduleHourRule?: string
    /** 天规则 */
    scheduleDayRule?: {
        /** 1~31 */
        day: string
        /** 0~24，间隔 15 分钟，例：00:15 */
        time: string
    }
    /** 工作日规则 */
    /** 0~24，间隔 15 分钟，例：00:15 */
    scheduleWeekdayRule?: string
    /** 周规则 */
    scheduleWeekRule?: {
        /** 1~52 */
        week: string
        /** 0~6，表示一~日 */
        days: string[]
        /** 0~24，间隔 15 分钟，例：00:15 */
        time: string
    }
    /** 月规则 */
    scheduleMonthRule?: {
        /** 1~12 */
        month: string
        /** 1~31 */
        days: string[]
        /** 0~24，间隔 15 分钟，例：00:15 */
        time: string
    }
}

// export type ManuallyTriggerConfig = Record<string, object>

// 创建单条记录节点
export type CreateSingleRecordActionConfig = DataSourceRelayConfig & {
    fields: NodeFieldContent[]
}
// 创建多条记录节点
export type CreateRecordActionConfig = DataSourceRelayConfig & {
    nodeId: string
    fields: NodeFieldContent[]
}

export type UpdateRecordActionConfig = {
    selectType?: FlowSelectRecordType
    nodeId: string // 引用的节点id
    fields: NodeFieldContent[]
}

// 查询单条记录节点
export type FindSingleRecordActionConfig = DataSourceRelayConfig & {
    selectType: FlowSelectSourceType
    nodeId: string
    sort: Sorter[]
    filter: FilterFormType
}

// 查询多条记录节点
export type FindRecordActionConfig = DataSourceRelayConfig & {
    selectType: FlowSelectSourceType
    nodeId: string
    sort: Sorter[]
    filter: FilterFormType
}

export enum LoopErrorHandlerEnum {
    'CONTINUE' = 'CONTINUE',
    'BREAK' = 'BREAK',
    'RETURN' = 'RETURN'
}

export type SubProcessArg = {
    id: string
    name: string
    innerType: TypeInstanceMap
    value?: VariableADTvalue
}

export type LoopTimesConfig = {
    start: VariableADTvalue
    end: VariableADTvalue
    step: VariableADTvalue
    subProcessId: string
    args: SubProcessArg[]
}

export type LoopRecordsConfig = {
    nodeId: string
    subProcessId: string
    args: SubProcessArg[]
}
// 循环节点
export type LoopNodeActionConfig = MakeADT<
    'loopType',
    {
        BASE_ON_TIMES: {
            timesConfig: Partial<LoopTimesConfig>
            loopErrorHandler: LoopErrorHandlerEnum
        }
        BASE_ON_RECORDS: {
            recordsConfig: Partial<LoopRecordsConfig>
            loopErrorHandler: LoopErrorHandlerEnum
        }
    }
>

// CREATE_RECORD_TRIGGER: NodeProtocol & { config?: CreateRecordTriggerConfig }
// UPDATE_RECORD_TRIGGER: NodeProtocol & { config?: UpdateRecordTriggerConfig }
export type ApproveActionConfig = {
    type: 'MANUALLY' | 'AUTO'
    approverSettingType: 'SELECTED' | 'SELF_SELECT'
    approvers: ApproverUser[]
    signType: 'COUNTER_SIGN' | 'PARALLEL_SIGN'
    fieldPermissions: FieldPermission[]
    emptyApprover: {
        type: 'REJECT' | 'AGREE' | 'SELECTED'
        approvers: ApproverUser[]
    }
    requireComment: boolean
}

export type InitiateApproveActionConfig = {
    approveObjectId: string
    approveFlowId: string
}

export type CarbonCopyActionConfig = {
    users: ApproverUser[]
}

// export type FindRecordActionConfig = Record<string, object>

export type ConditionConfig = {
    filter: FilterFormType
}

export type SendMsgWxRootConfigMsgType = 'text'

export type SendMsgWxRootConfig = {
    id: string
    msgtype: SendMsgWxRootConfigMsgType
    text: {
        // content: string
        editorContent: RichTextContentProtocol
        mentioned_list: string[]
        mentioned_mobile_list: string[]
    }
}

export type WxTemplateMessageConfig = {
    personList: VariableADTvalue[]
    templateId: string
    values: { variableName: string; value: VariableADTvalue }[]
    url: RichTextContentProtocol
}

export type SendMsgDingTalkRobotConfigMsgType = 'text' | 'link' | 'markdown'

export type SendMsgDingTalkRobotConfig = {
    id: string
    msgtype: SendMsgDingTalkRobotConfigMsgType
    text?: {
        editorContent: RichTextContentProtocol
    }
    at: {
        isAtAll?: boolean
        atMobiles?: string[]
        atUserIds?: string[]
    }
    markdown?: {
        title: string
        text: string
        editorText: RichTextContentProtocol
    }
    link?: {
        editorMessageUrl: RichTextContentProtocol
        editorTitle: RichTextContentProtocol
        picUrl: string
        editorText: RichTextContentProtocol
    }
}

/** 动作流相关节点配置 */
export type OpenPageActionConfig = {
    openPageId: string
    pageOpenType: RecordOpenType // 打开页面的方式
}

export type OpenLinkActionConfig = {
    // openLink: string
    link?: RichTextContentProtocol
}

export type OpenFormPageActionConfig = DataSourceRelayConfig & {
    formPageId: string // 表单页面 id
    pageOpenType: RecordOpenType // 打开页面的方式
}

export type OpenRecordPageActionConfig = {
    dataSourceId?: string // 不一定存在数据源 Id，如果选择当前记录，就不需要数据源 Id 而是直接使用当前记录的数据源
    recordPageId: string // 记录页面 id
    pageOpenType: RecordOpenType // 打开页面的方式
    filter: FilterFormType // 筛选  当 selectedRecordType 为 MATCHED_RECORD 时需要，用于筛选出需要打开的记录
    /**
     *  CURRENT_RECORD = 'CURRENT_RECORD',
     *  MATCHED_RECORD = 'MATCHED_RECORD',
     *  UPSTREAM_RECORD = 'UPSTREAM_RECORD'
     */
    selectedRecordType: OpenDetailPageSelectedRecordType
    upstreamRecordNodeId?: string // 上游记录 id，当 selectedRecordType 为 UPSTREAM_RECORD 时需要
}

export type OpenRecordEditPageActionConfig = {
    dataSourceId?: string // 不一定存在数据源 Id，如果选择当前记录，就不需要数据源 Id 而是直接使用当前记录的数据源
    recordEditPageId: string // 记录页面 id
    pageOpenType: RecordOpenType // 打开页面的方式
    filter: FilterFormType // 筛选
    /**
     *  CURRENT_RECORD = 'CURRENT_RECORD',
     *  MATCHED_RECORD = 'MATCHED_RECORD',
     *  UPSTREAM_RECORD = 'UPSTREAM_RECORD'
     */
    selectedRecordType: OpenDetailPageSelectedRecordType
    upstreamRecordNodeId?: string // 上游记录 id，当 selectedRecordType 为 UPSTREAM_RECORD 时需要
}

export type NotificationActionConfig = {
    notificationContent: AnyObject // TiptapEditorJSONContent
    notificationStatus: 'SUCCESS' | 'ERROR' | 'INFO'
}

export type CopyActionConfig = {
    copyText: TiptapEditorJSONContent
}

export type SendEmailActionConfig = {
    senderAccountId: string
    senderAccountType: SenderAccountType
    title: RichTextContentProtocol
    content: RichTextContentProtocol
    // cc 抄送人
    cc: EmailParticipant[]
    // 收件人
    recipients: EmailParticipant[]
}

export type StationMessageActionConfig = {
    title: RichTextContentProtocol
    personList: ApproverUser[]
    content: RichTextContentProtocol
}

export type ShareToWechatActionConfig = {
    title: string
    desc: string
    imgUrl: string
}

export type IClickActionConfig = {
    email: RichTextContentProtocol
    name: RichTextContentProtocol
    iClickId: string
    mobile: RichTextContentProtocol
}

export type DownloadFileActionConfig = {
    fileUrl: VariableADTvalue // TiptapEditorJSONContent
}

export type CallActionConfig = {
    phone: VariableADTvalue
}

export type AliyunMessageActionConfig = {
    id: string
    mobileList: VariableADTvalue
    signatureId: string
    templateId: string
    values: { variableName: string; value: VariableADTvalue }[]
}

export interface NodeStatusMessage {
    status?: {
        dsId?: string
        status: 'NOT_START' | 'RUNNING' | 'SUCCESS' | 'FAILED'
        log: string
        executeTime: Date
        output: { label: string; value: string | boolean; type: string }[][]
    }
}

export type WeChatPayActionConfig = WeChatPayAction['data']['wechatPay']

export type WechatTemplateMsgConfig = WechatTemplateMsgAction['data']['wechatTemplateMsg']

export type StripeActionConfig = StripeAction['data']['stripe']

export type TriggerNodeConfig =
    | CreateRecordTriggerConfig
    | UpdateRecordTriggerConfig
    | MatchConditionTriggerConfig
    | InitiateApproveTriggerConfig
    | ScheduledTriggerConfig
    | ClickTriggerConfig
    | VariableTriggerConfig

export type ActionNodeConfig =
    | CreateRecordActionConfig
    | CreateSingleRecordActionConfig
    | FindRecordActionConfig
    | FindSingleRecordActionConfig
    | UpdateRecordActionConfig
    | SendMsgWxRootConfig
    | ApproveActionConfig
    | CarbonCopyActionConfig
    | SendMsgDingTalkRobotConfig
    | SendEmailActionConfig
    | StationMessageActionConfig
    | IClickActionConfig
    | LoopNodeActionConfig
    // 动作流
    | OpenPageActionConfig
    | OpenLinkActionConfig
    | OpenFormPageActionConfig
    | OpenRecordPageActionConfig
    | OpenRecordEditPageActionConfig
    | NotificationActionConfig
    | CopyActionConfig
    | InitiateApproveActionConfig
    | ShareToWechatActionConfig
    | DownloadFileActionConfig
    | AliyunMessageActionConfig
    | CallActionConfig
    | WeChatPayActionConfig
    | StripeActionConfig

export type ConditionNodeConfig = NodeStatusMessage & ConditionConfig

export type EndNodeConfig = {}

export type NodeConfig = TriggerNodeConfig | ActionNodeConfig | ConditionNodeConfig

export interface NodeProtocol {
    name: string
    nodeType: NodeTypes
}

export type SendMsgWxRootNode = NodeProtocol & { config?: SendMsgWxRootConfig }

export type SendMsgDingTalkRobotRootNode = NodeProtocol & { config?: SendMsgDingTalkRobotConfig }

export type SendEmailActionNode = NodeProtocol & { config?: SendEmailActionConfig }

export type StationMessageActionNode = NodeProtocol & { config?: StationMessageActionConfig }

export type WxTemplateMessageNode = NodeProtocol & { config?: WxTemplateMessageConfig }

export type NodeADTConfigure = MakeADT<
    'nodeType',
    {
        CREATE_RECORD_TRIGGER: NodeProtocol & { config?: CreateRecordTriggerConfig }
        UPDATE_RECORD_TRIGGER: NodeProtocol & { config?: UpdateRecordTriggerConfig }
        MATCH_CONDITION_TRIGGER: NodeProtocol & { config?: MatchConditionTriggerConfig }
        INITIATE_APPROVE_TRIGGER: NodeProtocol & { config?: InitiateApproveTriggerConfig }
        SCHEDULED_TRIGGER: NodeProtocol & { config?: ScheduledTriggerConfig }
        ARG_TRIGGER: NodeProtocol & { config?: VariableTriggerConfig }
        // MANUALLY_TRIGGER: NodeProtocol & { config?: ManuallyTriggerConfig }
        CREATE_SINGLE_RECORD_ACTION: NodeProtocol & { config?: CreateSingleRecordActionConfig }
        CREATE_RECORD_ACTION: NodeProtocol & { config?: CreateRecordActionConfig }
        UPDATE_RECORD_ACTION: NodeProtocol & { config?: UpdateRecordActionConfig }
        FIND_SINGLE_RECORD_ACTION: NodeProtocol & { config?: FindSingleRecordActionConfig }
        FIND_RECORD_ACTION: NodeProtocol & { config?: FindRecordActionConfig }
        LOOP_ACTION: NodeProtocol & { config?: LoopNodeActionConfig }
        WX_ROBOT_ACTION: SendMsgWxRootNode
        DINGTALK_ROBOT_ACTION: SendMsgDingTalkRobotRootNode
        APPROVE_ACTION: NodeProtocol & { config?: ApproveActionConfig }
        CARBON_COPY_ACTION: NodeProtocol & { config?: CarbonCopyActionConfig }
        CONDITION: NodeProtocol & { config?: ConditionConfig }
        SEND_EMAIL_ACTION: SendEmailActionNode
        STATION_MESSAGE_ACTION: StationMessageActionNode
        ICLICK_ACTION: NodeProtocol & { config: IClickActionConfig }
        WECHAT_PAY: NodeProtocol & { config: WeChatPayActionConfig }
        WECHAT_TEMPLATE_MSG_ACTION: NodeProtocol & { config: WxTemplateMessageNode }
    }
>

export interface BaseFlowNode<T = NodeConfig> {
    id: string
    selected?: boolean
    data: { name: string; nodeType: NodeTypes; config?: T }
    position: { x: number; y: number }
}

export interface TriggerFlowNode<T = TriggerNodeConfig> extends BaseFlowNode<T> {
    type: 'TRIGGER'
    data: { name: string; nodeType: NodeTypes; config?: T } & NodeStatusMessage
}

export interface ActionFlowNode<T = ActionNodeConfig> extends BaseFlowNode<T> {
    type: 'ACTION'
    data: { name: string; nodeType: NodeTypes; config?: T } & NodeStatusMessage
}

export interface ConditionFlowNode<T = ConditionNodeConfig> extends BaseFlowNode<T> {
    type: 'CONDITION'
    data: { name: string; nodeType: NodeTypes; config?: T } & NodeStatusMessage
}

export interface EndFlowNode<T = EndNodeConfig> extends BaseFlowNode<T> {
    type: 'END'
    data: { name: string; nodeType: NodeTypes; config?: T } & NodeStatusMessage
}

export interface BaseFlowEdge {
    id: string
    source: string
    target: string
    data?: {
        status?: { status?: 'SUCCESS' | 'FAILED' }
    }
}

export interface CommonFlowEdge extends BaseFlowEdge {
    type: 'common'
}
export interface ConditionSourceFlowEdge extends BaseFlowEdge {
    type: 'conditionSource'
}
export interface ConditionInnerFlowEdge extends BaseFlowEdge {
    type: 'conditionInner'
}
export interface ConditionTargetFlowEdge extends BaseFlowEdge {
    type: 'conditionTarget'
}

export type FlowNode<T = NodeConfig> = TriggerFlowNode<T> | ActionFlowNode<T> | ConditionFlowNode<T> | EndFlowNode

export type FlowEdge = CommonFlowEdge | ConditionSourceFlowEdge | ConditionInnerFlowEdge | ConditionTargetFlowEdge

export type FlowPageMode = 'flow' | 'runLog'

export type FlowNodeButtonClickPayload = {
    edge: Edge
    operator: 'common' | 'addBeforeCondition' | 'addInCondition' | 'addCondition' | 'addAfterCondition'
}

export type TriggerDataSourceNodeConfig =
    | CreateRecordTriggerConfig
    | UpdateRecordTriggerConfig
    | MatchConditionTriggerConfig
    | InitiateApproveTriggerConfig
    // | ScheduledTriggerConfig // 定时触发器不需要配置数据源
    // | ManuallyTriggerConfig
    | CreateRecordActionConfig

export type WorkflowType = 'automation' | 'approval' | 'action' | 'subProcess'

export type Workflow = {
    id: string
    type: WorkflowType
    name: string
    description: string
    unConfigured: boolean
    changed?: boolean
    content?: {
        nodes: FlowNode[]
        edges: FlowEdge[]
    }
    updatedTime: Date
    updatedBy: string
    createdTime: Date
    createdBy: string
    enabled: boolean
    errorMap?: Record<string, boolean>
}

export type RunStatus = 'RUNNING' | 'SUCCESS' | 'FAILED'

export type UpdateWorkflowLogPayload = {
    /**
     * 工作流id 必传
     */
    workflowId: string
    /**
     * 工作流实整体运行状态枚举值，有 RUNNING, SUCCESS , FAILED，必传
     */
    status: RunStatus
    /**
     * 工作流实例id，必传
     */
    workflowInstanceId: string
    /**
     * 节点id，必传
     */
    nodeId: string
    nodeStatus: {
        /**
         * 节点状态数据结构，与自动化的节点状态结构一致 ，必传, // 有 RUNNING, SUCCESS , FAILED，必传
         */
        status: RunStatus
        input: { label: string; type: string; value: string }[]
        output: { label: string; type: string; value: string }[]
    }
}

export type NodeInvokeResult = UpdateWorkflowLogPayload & {
    runtimeParams: AnyObject
    result: AnyObject
}


export type UsedWorkflow = {
    workflowId: string
    workflowType: WorkflowType
    workflowName: string
    nodeId: string
    nodeName: string
    nodeType: NodeTypes
}
