import { useElementSize } from '@byecode/ui'
import type { ButtonAction, CardBlockAbstract, CardBlockItemConfig, CardStyle } from '@lighthouse/core'
import { BlockSize, CARD_STYLE } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import { IconRender, isEmptyRichTextValue, RichTextEditor } from '@lighthouse/shared'
import { Box } from '@mantine/core'
import equal from 'fast-deep-equal'
import { omit } from 'rambda'
import type { FC } from 'react'
import React, { memo, useMemo } from 'react'
import styled from 'styled-components'

const CardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const CardIcon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`
const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    word-break: break-word;
`

const StyledRichTextEditor = styled(RichTextEditor).withConfig<{ fontSize?: number; lineHeight?: string; textAlign?: string }>({
    shouldForwardProp: p => !['fontSize', 'lineHeight', 'textAlign'].includes(p)
})`
    .ProseMirror {
        font-size: ${({ fontSize }) => `${fontSize}px`};
        line-height: ${({ lineHeight }) => lineHeight};
        text-align: ${({ textAlign }) => textAlign};
    }
`

const ICON_SIZE_MAP = new Map([
    [BlockSize.xs, 24],
    [BlockSize.sm, 32],
    [BlockSize.md, 50],
    [BlockSize.lg, 120],
    [BlockSize.xl, 180]
])

const FONT_STYLES_MAP = new Map([
    [
        BlockSize.xs,
        {
            fontSize: 12,
            lineHeight: '22px'
        }
    ],
    [BlockSize.sm, { fontSize: 14, lineHeight: '22px' }],
    [
        BlockSize.md,
        {
            fontSize: 16,
            lineHeight: '24px'
        }
    ],
    [
        BlockSize.lg,
        {
            fontSize: 20,
            lineHeight: '28px'
        }
    ],
    [
        BlockSize.xl,
        {
            fontSize: 24,
            lineHeight: '32px'
        }
    ]
])

/**
 * 设计要求默认风格透明底
 * @see https://roadmap.byecode.site/P/4e63552d99fd4b01851c36cfbedd91de/D/a130da191ae241bebf623c645c7977e2/a/95ee6ff25df04fb689c7e9f55159ac04/d/ChD21jm645da569cncrLpuCzd8qclma7/v/view-g646f22c9uc1/r/9dc4051519dc469488ad5bf4d8a44690
 */
const BACKGROUND_COLOR = new Map([
    [CARD_STYLE.default, undefined],
    [CARD_STYLE.block, 'var(--color-gray-100)'],
    [CARD_STYLE.card, 'var(--color-white)']
])

type ItemProps = {
    cols: number
    data: CardBlockItemConfig
    config: RichTextEditorProps['config']
    size: BlockSize
    isHorizontal: boolean
    style?: CardStyle
    onClick?: (action: ButtonAction) => void
}

const Item = memo(
    ({ data, cols, size, style, config, isHorizontal, onClick }: ItemProps) => {
        const hasHeading = useMemo(() => data.heading && !isEmptyRichTextValue(data.heading, config), [config, data.heading])
        const hasIntroduction = useMemo(
            () => data.introduction && !isEmptyRichTextValue(data.introduction, config),
            [config, data.introduction]
        )

        return (
            <Box
                sx={{
                    padding: 'var(--block-padding)',
                    flex: `0 0 ${Math.floor((100 / cols) * 100) / 100}%`,
                    maxWidth: `${Math.floor((100 / cols) * 100) / 100}%`
                }}
            >
                <Box
                    sx={{
                        padding: 10,
                        height: '100%',
                        display: 'flex',
                        flexDirection: isHorizontal ? 'row' : 'column',
                        alignItems: 'center',
                        gap: isHorizontal ? 12 : 8,
                        cursor: 'pointer',
                        borderRadius: 12,
                        border: `1px solid ${style === CARD_STYLE.card ? 'var(--color-gray-200)' : 'transparent'}`,
                        backgroundColor: style && BACKGROUND_COLOR.get(style),
                        '&:hover': {
                            border: style === CARD_STYLE.block ? '1px solid var(--color-gray-200)' : undefined,
                            boxShadow: style === CARD_STYLE.card ? '0px 0px 6px 0px rgba(56, 56, 56, 0.12)' : undefined
                        }
                    }}
                    onClick={() => onClick?.(data.action)}
                >
                    {data.iconDecoration?.value && (
                        <CardIcon>
                            <IconRender
                                value={data.iconDecoration}
                                color={data.iconDecoration?.type === 'icon' ? data.iconColor : undefined}
                                size={ICON_SIZE_MAP.get(size)}
                                readonly
                                isCompact
                            />
                        </CardIcon>
                    )}
                    <CardContent style={{ alignItems: isHorizontal ? 'flex-start' : 'center' }}>
                        {hasHeading && (
                            <StyledRichTextEditor
                                styles={{ editorContent: { textAlign: isHorizontal ? 'left' : 'center' } }}
                                {...FONT_STYLES_MAP.get(size)}
                                readonly
                                value={data.heading}
                                config={config}
                            />
                        )}
                        {hasIntroduction && (
                            <StyledRichTextEditor
                                readonly
                                textAlign={isHorizontal ? undefined : 'center'}
                                fontSize={12}
                                lineHeight="18px"
                                value={data.introduction}
                                config={config}
                            />
                        )}
                    </CardContent>
                </Box>
            </Box>
        )
    },
    (a, b) => equal(omit('onClick', a), omit('onClick', b))
)

interface CardBlockProps {
    blockData: CardBlockAbstract
    config?: RichTextEditorProps['config']
    onClick?: (action: ButtonAction) => void
}

const CardBlock: FC<CardBlockProps> = ({ blockData, config, onClick }) => {
    const { config: { style, size = 'md', list = [], direction = 'vertical', layout = 'auto', cols: fixedCols } = {} } = blockData

    const { ref, width } = useElementSize<HTMLDivElement>()

    const autoCols = useMemo(() => {
        if (width <= 460) {
            switch (size) {
                case BlockSize.xs: {
                    return 3
                }
                case BlockSize.sm: {
                    return 2
                }

                default: {
                    return 1
                }
            }
        }
        if (width <= 680) {
            switch (size) {
                case BlockSize.xs: {
                    return 4
                }
                case BlockSize.sm: {
                    return 3
                }
                case BlockSize.md: {
                    return 2
                }

                default: {
                    return 1
                }
            }
        }

        if (width <= 880) {
            switch (size) {
                case BlockSize.xs: {
                    return 5
                }
                case BlockSize.sm: {
                    return 4
                }
                case BlockSize.md: {
                    return 3
                }

                default: {
                    return 2
                }
            }
        }

        if (width <= 1024) {
            switch (size) {
                case BlockSize.xs: {
                    return 6
                }
                case BlockSize.sm: {
                    return 5
                }
                case BlockSize.md: {
                    return 4
                }

                default: {
                    return 3
                }
            }
        }

        if (width <= 1400) {
            switch (size) {
                case BlockSize.xs: {
                    return 7
                }
                case BlockSize.sm: {
                    return 6
                }

                case BlockSize.md: {
                    return 5
                }
                case BlockSize.lg: {
                    return 4
                }
                default: {
                    return 3
                }
            }
        }

        if (width > 1400) {
            switch (size) {
                case BlockSize.xs: {
                    return 8
                }
                case BlockSize.sm: {
                    return 7
                }

                case BlockSize.md: {
                    return 6
                }
                case BlockSize.lg: {
                    return 5
                }
                default: {
                    return 4
                }
            }
        }

        return 1
    }, [size, width])

    const cols = layout === 'fixed' && fixedCols ? fixedCols : autoCols

    const isHorizontal = direction === 'horizontal'

    return (
        <CardWrapper
            style={{
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                height: width === 0 ? 0 : undefined
            }}
            ref={ref}
        >
            {list.map((item, index) => (
                <Item
                    key={index}
                    data={item}
                    cols={cols}
                    size={size}
                    isHorizontal={isHorizontal}
                    style={style}
                    config={config}
                    onClick={onClick}
                />
            ))}
        </CardWrapper>
    )
}

export default CardBlock
