import { Box, tinyButtons, useContextPopoverHeight, usePopoverContext } from '@byecode/ui'
import { mergeRefs } from '@byecode/ui/hooks/useMergedRef'
import type { DataSourceAbstract, Field, FieldBlockWithDsId, VariableADTvalue } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import { Text } from '@mantine/core'
import { filter } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { VariableSourceType } from '../../../types'
import type { UploadDropZoneProps } from '../../UploadManage'
import { PageLinkOption, sourceNameMap } from '../constant'
import { type VariableOptions, type VariableTree } from '../types'
import { VariableCustom } from './Custom'
import { FieldList } from './FieldList'
import { VariableItem } from './Item'
import VariableSelectDataSource from './SelectDataSource'
import { VariableSystem } from './System'

export interface VariableSelectDropDownProps {
    value?: VariableADTvalue
    field?: Field
    // 是否多选
    isMultiple?: boolean
    // 禁用输入具体值， 选择日期等
    disableInput?: boolean
    // 上传参数
    uploadProps?: Pick<UploadDropZoneProps, 'uploadOptions' | 'accept' | 'multiple'>
    // 禁用第三方资源库图片
    disabledPexels?: boolean
    // 启用页面链接变量
    enablePageLink?: boolean
    selectDataSourceProps?: {
        // 启用查询数据源变量
        enable?: boolean
        // 查询数据源弹窗内可使用输入框变量
        fieldBlocksWithDsId?: FieldBlockWithDsId[]
        // 验证字段是否可使用
        validateFieldType?: (field: Field) => boolean
    }
    // 无可选变量缺省
    empty?: React.ReactNode
    // 数据源列表 目前使用与SelectDataSourceVariable
    dataSourceList?: DataSourceAbstract[]

    // 可选择页面或节点的 字段
    options?: VariableOptions
    // 可选系统变量
    systemOption?: VariableTree
    // 当前登录用户 可选字段
    userOption?: VariableTree
    // 当前数据表 可选字段
    dataSourceOption?: VariableTree
    // 输入框的值 可选block
    inputOption?: VariableTree
    // 视图的值 可选字段
    viewOption?: VariableTree
    // 表单容器的值 可选字段
    formOption?: VariableTree
    // 筛选器的值 可选筛选器
    filterOptions?: VariableTree[]

    style?: React.CSSProperties
    onChange: (value: VariableADTvalue) => void
    onOpenSelectDataSourceModal?: () => void
    onClose?: () => void
}

const SCxListContainer = styled.div``

const SCxDropDownContainer = styled.div<{ maxHeight?: number }>`
    width: 100%;
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    overflow: hidden auto;
    ${tinyButtons}

    &>div:not(.byecode-popover-dropdown):not(:first-child) {
        &::before {
            display: block;
            content: '';
            width: 100%;
            height: 0.5px;
            background-color: var(--color-gray-200);
            margin: 8px 0;
        }
    }
`

export const VariableSelectDropDown = React.forwardRef<HTMLDivElement, VariableSelectDropDownProps>(
    (
        {
            value,
            field,
            isMultiple,
            disableInput,
            disabledPexels,
            uploadProps,
            enablePageLink,
            selectDataSourceProps,
            empty,
            dataSourceList,

            options,
            systemOption,
            userOption,
            dataSourceOption,
            inputOption,
            viewOption,
            formOption,
            filterOptions,

            style,
            onOpenSelectDataSourceModal,
            onChange,
            onClose
        },
        ref
    ) => {
        const inputType = field?.type || 'text'
        const { context } = usePopoverContext()
        const [maxHeight, internalRef] = useContextPopoverHeight({ context })
        const hasListOptions = useMemo(() => {
            if (!options) {
                return []
            }
            const usedOptions = filter(
                option => !(option.type === VariableSourceType.parentPage || option.type === VariableSourceType.page),
                options
            )
            return filter(option => {
                return option?.list ? option.list.length > 0 : false
            }, usedOptions)
        }, [options])

        const isEmpty = useMemo(() => {
            const emptyOption = !options || (options && options.flatMap(option => option.list).length === 0)
            const emptyUserOption = !userOption || (userOption && userOption.children.length === 0)
            const emptyDataSourceOption = !dataSourceOption || (dataSourceOption && dataSourceOption.children.length === 0)
            const emptyInputOption = !inputOption || (inputOption && inputOption.children.length === 0)
            const emptyViewOption = !viewOption || (viewOption && viewOption.children.length === 0)
            const emptyFilterOption = !filterOptions || (filterOptions && filterOptions.length === 0)
            const emptyFormOption = !formOption || (formOption && formOption.children.length === 0)
            const emptySystemOption = !systemOption || (systemOption && systemOption.children.length === 0)
            return (
                emptyInputOption &&
                emptyDataSourceOption &&
                emptyUserOption &&
                emptyOption &&
                emptyViewOption &&
                emptyFilterOption &&
                emptyFormOption &&
                emptySystemOption &&
                disableInput
            )
        }, [dataSourceOption, disableInput, filterOptions, formOption, inputOption, options, systemOption, userOption, viewOption])

        const blockOptionEle = useMemo(() => {
            if (!inputOption && !viewOption && !formOption) {
                return null
            }
            return (
                <Box>
                    <Text size={12} color="var(--color-gray-500)" style={{ lineHeight: '32px', padding: '0 16px' }}>
                        组件数据
                    </Text>
                    {inputOption && (
                        <VariableItem value={value} data={inputOption} onChange={onChange} inputType={inputType} onClose={onClose} />
                    )}
                    {viewOption && (
                        <VariableItem value={value} data={viewOption} onChange={onChange} inputType={inputType} onClose={onClose} />
                    )}
                    {formOption && (
                        <VariableItem value={value} data={formOption} onChange={onChange} inputType={inputType} onClose={onClose} />
                    )}
                </Box>
            )
        }, [formOption, inputOption, inputType, onChange, onClose, value, viewOption])

        const pageOptionEle = useMemo(() => {
            const parentPageOptions = options?.find(option => option.type === VariableSourceType.parentPage)
            const currentPageOptions = options?.find(option => option.type === VariableSourceType.page)
            const currentPageOption = currentPageOptions?.list?.[0]
            if (!currentPageOption && (parentPageOptions?.list ?? []).length === 0 && !enablePageLink) {
                return null
            }

            return (
                <Box>
                    <Text size={12} color="var(--color-gray-500)" style={{ lineHeight: '32px', padding: '0 16px' }}>
                        页面数据
                    </Text>
                    {enablePageLink && (
                        <VariableItem value={value} data={PageLinkOption} onChange={onChange} inputType={inputType} onClose={onClose} />
                    )}
                    {currentPageOption && (
                        <VariableItem value={value} data={currentPageOption} onChange={onChange} inputType={inputType} onClose={onClose} />
                    )}
                    {parentPageOptions?.list.map(option => (
                        <VariableItem
                            key={option.id}
                            value={value}
                            data={option}
                            onChange={onChange}
                            inputType={inputType}
                            onClose={onClose}
                        />
                    ))}
                </Box>
            )
        }, [enablePageLink, inputType, onChange, onClose, options, value])

        const globalOptionEle = useMemo(() => {
            if (!userOption && !systemOption) {
                return null
            }
            return (
                <Box>
                    <Text size={12} color="var(--color-gray-500)" style={{ lineHeight: '32px', padding: '0 16px' }}>
                        全局数据
                    </Text>
                    {userOption && (
                        <VariableItem value={value} data={userOption} onChange={onChange} inputType={inputType} onClose={onClose} />
                    )}
                    {systemOption && <VariableSystem systemOption={systemOption} value={value} onChange={onChange} onClose={onClose} />}
                </Box>
            )
        }, [inputType, onChange, onClose, systemOption, userOption, value])

        return (
            <SCxDropDownContainer maxHeight={maxHeight} ref={ref}>
                {(!disableInput || selectDataSourceProps?.enable) && !!field?.type && (
                    <div>
                        {!disableInput && field?.type && (
                            <VariableCustom
                                field={field}
                                value={value?.type === VariableType.VALUE ? value : undefined}
                                isMultiple={isMultiple}
                                uploadProps={uploadProps}
                                disabledPexels={disabledPexels}
                                onChange={onChange}
                                onClose={onClose}
                            />
                        )}
                        {selectDataSourceProps?.enable && (
                            <VariableSelectDataSource
                                value={value?.type === VariableType.SELECT_DATASOURCE ? value : undefined}
                                onOpen={onOpenSelectDataSourceModal}
                            />
                        )}
                    </div>
                )}
                {filterOptions &&
                    filterOptions.map(option => (
                        <VariableItem
                            value={value}
                            key={option.id}
                            data={option}
                            onChange={onChange}
                            inputType={inputType}
                            onClose={onClose}
                        />
                    ))}
                {isEmpty && empty}
                {blockOptionEle}
                {pageOptionEle}
                {hasListOptions.length > 0 &&
                    hasListOptions.map(option => (
                        <SCxListContainer key={option.type}>
                            <Text size={12} style={{ padding: '0 12px', lineHeight: '32px' }} color="var(--color-gray-500)">
                                {sourceNameMap[option.type]}
                            </Text>
                            {option.list.map(item => {
                                return (
                                    <VariableItem
                                        value={value}
                                        key={item.id}
                                        data={item}
                                        onChange={onChange}
                                        onClose={onClose}
                                        inputType={inputType}
                                    />
                                )
                            })}
                        </SCxListContainer>
                    ))}
                {dataSourceOption && (
                    <>
                        <FieldList data={dataSourceOption} inputType={inputType} onChange={onChange} onClose={onClose} />
                    </>
                )}
                {globalOptionEle}
            </SCxDropDownContainer>
        )
    }
)
