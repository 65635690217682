import clsx from 'clsx'
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLatest } from 'react-use'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { useMove } from '../../hooks/useMove'
import { useUncontrolled } from '../../hooks/useUncontrolled'
import type { StyleComponentProps } from '../../theme/types'
import { Box } from '../Box'
import type { InputProps } from '../Input'
import { Input } from '../Input'
import type { SliderStyleNames } from './styles'
import { useStyles } from './styles'

export interface SliderProps extends StyleComponentProps<SliderStyleNames>, Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
    defaultValue?: number
    value?: number
    onChange?: (v: number) => void
    onChangeStart?: (v: number) => void
    onChangeEnd?: (v: number) => void

    disabled?: boolean
    readonly?: boolean
    min: number
    max: number
    step?: number
    icon?: React.ReactNode
    hideIcon?: boolean
    hideInput?: boolean
    inputProps?: InputProps
}

export const Slider = forwardRef<HTMLDivElement, SliderProps>((props, ref) => {
    const {
        classNames,
        className,
        styles,
        unstyled,

        disabled: _disabled,
        readonly,
        value: propValue,
        defaultValue,
        onChange: propOnChange,
        onChangeStart,
        onChangeEnd,
        min,
        max,
        step = 1,
        icon,
        hideIcon,
        hideInput,
        inputProps,

        ...rest
    } = props

    const { disabled } = useComponentConfig('slider', { disabled: readonly || _disabled })
    const { classes } = useStyles({}, { name: 'Slider', classNames, styles, unstyled })

    const [value, onChange] = useUncontrolled({ value: propValue, defaultValue, onChange: propOnChange, finalValue: min })
    const [draftInputValue, setDraftInputValue] = useState<string | number>(value)

    useEffect(() => {
        setDraftInputValue(value.toString())
    }, [value])

    const latest = useLatest({ value, onChange })

    const handleChange = useCallback(
        (v: number) => {
            latest.current.onChange(v)
        },
        [latest]
    )

    const { ref: trackRef, active } = useMove(
        useCallback(
            ({ ratio: { x } }) => {
                const v = Math.round((x * (max - min)) / step) * step + min
                if (v === latest.current.value) {
                    return
                }
                handleChange(v)
            },
            [handleChange, latest, max, min, step]
        ),
        {
            disabled: disabled || readonly,
            onStart: ({ ratio: { x } }) => {
                const v = Math.round((x * (max - min)) / step) * step + min
                onChangeStart?.(v)
            },
            onEnd: ({ ratio: { x } }) => {
                const v = Math.round((x * (max - min)) / step) * step + min
                onChangeEnd?.(v)
            }
        }
    )

    const thumbRef = useRef<HTMLDivElement>(null)
    const onTrackKeyDownCapture = (e: React.KeyboardEvent) => {
        if (disabled || readonly) {
            return
        }

        switch (e.key) {
            case 'ArrowUp':
            case 'ArrowRight': {
                const v = Math.max(min, Math.min(max, value + step))
                handleChange(v)
                break
            }

            case 'ArrowDown':
            case 'ArrowLeft': {
                const v = Math.max(min, Math.min(max, value - step))
                handleChange(v)
                break
            }

            default: {
                break
            }
        }
    }

    const ratio = useMemo(() => ((value - min) / (max - min)) * 100, [max, min, value])

    return (
        <Box ref={ref} className={clsx(className, classes.root)} {...rest}>
            {/* {!hideIcon && (icon ?? <IconFont type="MaxWidth" />)} */}
            <Box
                className={classes.track}
                ref={trackRef}
                tabIndex={-1}
                onKeyDownCapture={onTrackKeyDownCapture}
                onMouseDownCapture={e => {
                    e.preventDefault()
                    thumbRef.current?.focus()
                }}
            >
                <Box className={classes.rail} style={{ width: `${ratio}%` }} />
                {!readonly && (
                    <Box
                        className={classes.thumb}
                        ref={thumbRef}
                        tabIndex={0}
                        role="slider"
                        data-active={active || undefined}
                        style={{ left: `${ratio}%` }}
                    />
                )}
            </Box>
            {!hideInput && (
                <Input
                    style={{ width: 56 }}
                    placeholder=""
                    disabled={disabled}
                    value={draftInputValue}
                    onChange={e => setDraftInputValue(e.currentTarget.value)}
                    onBlur={e => {
                        const v = e.currentTarget.value
                        const number = Number(v)
                        const validNumber = Math.max(min, Math.min(max, Number.isNaN(number) ? min : (number / step) * step))
                        handleChange(validNumber)
                        setDraftInputValue(validNumber)
                    }}
                    onKeyDownCapture={e => {
                        if (e.key === 'Enter') {
                            const v = e.currentTarget.value
                            const number = Number(v)
                            const validNumber = Math.max(min, Math.min(max, Number.isNaN(number) ? min : (number / step) * step))
                            handleChange(validNumber)
                        }
                    }}
                    {...inputProps}
                />
            )}
        </Box>
    )
})
