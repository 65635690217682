import type { DataSourceAbstract, FieldInputADTValue } from '@lighthouse/core'
import type { ApplicationPreviewEnum, FieldInputError, SubFormErrors } from '@lighthouse/shared'
import {
    type CascadeParam,
    type CheckBoxParam,
    type PersonParam,
    type PhoneNumberParam,
    type RelativeFieldParam,
    type UseUploadFileSParameter,
    DEFAULT_LANG,
    zhCN
} from '@lighthouse/shared'
import type { UploadyProps } from '@rpldy/uploady'
import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'

export interface SubFormFieldInputContextType
    extends RelativeFieldParam,
        CascadeParam,
        Pick<PhoneNumberParam, 'onFetchSmsCode'>,
        PersonParam,
        CheckBoxParam {
    dataSourceInfo?: DataSourceAbstract
    previewType?: ApplicationPreviewEnum
    language: string
    dataSource?: DataSourceAbstract
    isValid: boolean
    errors?: SubFormErrors
    columnsWidth?: Record<string, number>
    getUploadyOptions?: (fieldId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    getRichTextUploadOptions?: (fieldId: string) => UploadyProps
    getVideoUploadyOption?: (fieldId: string) => Pick<UseUploadFileSParameter, 'info' | 'options'>
    onCellChange?: (recordId: string, columnId: string, fieldValue: FieldInputADTValue) => void
    onRecordCopy?: (id: string) => void
    onRecordRemove?: (id: string) => void
    onChangeSmsCode?: (recordId: string, columnId: string, code: string) => void
    onChangeColumnWidth?: (id: string, width: number, isFinished: boolean) => void
    onCellBlur?: (recordId: string, columnId: string) => void
}

export const SubFormFieldInputContext = createContext<SubFormFieldInputContextType>({
    language: DEFAULT_LANG,
    isValid: false
})

type SubFormFieldInputProviderProps = PropsWithChildren<{
    value: SubFormFieldInputContextType
}>

export const SubFormFieldInputProvider: FC<SubFormFieldInputProviderProps> = props => <SubFormFieldInputContext.Provider {...props} />

export const useSubFormFieldInputContext = () => useContext(SubFormFieldInputContext)
