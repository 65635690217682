import type {
    AppUser,
    FilterCommonCondition,
    FilterOption,
    GroupConfigure,
    GroupRecordCount,
    GroupTab,
    Sorter,
    ViewBlockAbstract
} from '@lighthouse/core'
import { getFilterBlockItemIdsInFilter, resolveFilter, useAtomData } from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import { useAtomValue } from 'jotai'
import { find } from 'rambda'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMount, useUpdateEffect } from 'react-use'

import { filterBlockOptionsAtom } from '@/atoms/blockRecordsDict/state'
import { pageBlocksAtom } from '@/atoms/page/state'
import { filterBlockIdsCacheAtomFamily } from '@/atoms/storage/state'

import type { FetchViewDataPayload, GetGroupRecordCountPayload, ReloadDataPayload } from './types'

interface UseViewBlockConfigPayload {
    appId: string
    envId: string
    pageId: string
    blockData: ViewBlockAbstract
    groupConfigure?: GroupConfigure
    quickFiltersRule: FilterCommonCondition[]
    searchVal: string
    sortCache: Sorter[]
    tabValue: string
    currentRecordId?: string
    tabList: GroupTab[]
    initTabList: GroupTab[]
    tabCountList?: GroupRecordCount[]
    personOptions: AppUser[]
    setTabValue: React.Dispatch<React.SetStateAction<string>>
    setTabList: (value: React.SetStateAction<GroupTab[]>) => void
    fetchViewData: (params: FetchViewDataPayload) => Promise<void>
    getGroupRecordCount: (params: GetGroupRecordCountPayload) => Promise<void>
}

export const useViewBlockConfig = (props: UseViewBlockConfigPayload) => {
    const {
        appId,
        envId,
        pageId,
        blockData,
        groupConfigure,
        quickFiltersRule,
        searchVal,
        sortCache,
        tabValue,
        currentRecordId,
        tabList,
        initTabList,
        tabCountList,
        personOptions,
        setTabValue,
        setTabList,
        fetchViewData,
        getGroupRecordCount
    } = props
    const groupByFieldIdRef = useRef(groupConfigure?.groupByFieldId)
    const { id, config } = blockData
    const { linkFilterController } = config
    const filterOptions = useAtomData(filterBlockOptionsAtom)
    const { filterBlockItemIds } = useMemo(() => getFilterBlockItemIdsInFilter(linkFilterController), [linkFilterController])
    const filterBlockIds = useMemo(() => filterBlockItemIds.map(item => item.blockId), [filterBlockItemIds])
    const filterValue = useAtomValue(filterBlockIdsCacheAtomFamily({ appId, envId, pageId, recordId: currentRecordId, filterBlockItemIds }))
    const filterBlocks = useAtomData(
        pageBlocksAtom,
        useCallback(
            s => s[pageId]?.filter(block => block.type === 'filter' && filterBlockIds.includes(block.id)) || [],
            [filterBlockIds, pageId]
        )
    )
    const [localFilterBlocks, setLocalFilterBlocks] = useState(filterBlocks)
    // const [localLinkFilterController, setLocalLinkFilterController] = useState(linkFilterController)

    const usedFilterOptions = useMemo(() => {
        return filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
            const id = `${filterItem.blockId}-${filterItem.itemId}`
            const options = filterOptions[id]
            if (options) {
                prev[id] = options
            }
            return prev
        }, {})
    }, [filterBlockItemIds, filterOptions])

    const [localFilterOptions, setLocalFilterOptions] = useState(usedFilterOptions)

    const resolvedFilter = useMemo(() => {
        if (!linkFilterController) {

            return
        }
        return resolveFilter({
            filter: linkFilterController,
            extraParams: {
                filterBlockParams: {
                    filterValue,
                    filterOptions: localFilterOptions,
                    filterBlocks: localFilterBlocks
                }
            }
        })
    }, [linkFilterController, filterValue, localFilterOptions, localFilterBlocks])

    const resolvedFilterRef = useRef(resolvedFilter)

    const reloadData = useCallback(
        (params: ReloadDataPayload) => {
            const { groupConfigure, quickFilters, search, pageNum } = params
            // groupCache: GroupConfigure | undefined, quickFilters: FilterCommonCondition[], search: string, pageNum?: number
            getGroupRecordCount({ groupConfigure, linkFilter: resolvedFilter, quickFilters, search })
            fetchViewData({ quickFilters, linkFilter: resolvedFilter, sorts: sortCache, tab: tabValue, search, pageNum })
        },
        [fetchViewData, getGroupRecordCount, resolvedFilter, sortCache, tabValue]
    )

    useUpdateEffect(() => {
        if (equal(localFilterOptions, usedFilterOptions)) {
            return
        }
        setLocalFilterOptions(usedFilterOptions)
    }, [usedFilterOptions])

    // useUpdateEffect(() => {
    //     if (equal(linkFilterController, localLinkFilterController)) {
    //         return
    //     }
    //     setLocalLinkFilterController(linkFilterController)
    // }, [linkFilterController])

    useUpdateEffect(() => {
        if (equal(filterBlocks, localFilterBlocks)) {
            return
        }
        setLocalFilterBlocks(filterBlocks)
    }, [filterBlocks])

    // 监听filter
    useUpdateEffect(() => {
        if (equal(resolvedFilterRef.current, resolvedFilter)) {
            return
        }
        resolvedFilterRef.current = resolvedFilter
        reloadData({
            groupConfigure,
            quickFilters: quickFiltersRule,
            search: searchVal,
            pageNum: 1
        })
    }, [resolvedFilter])

    useMount(() => {
        if (groupConfigure?.groupByFieldId) {
            getGroupRecordCount({ groupConfigure, quickFilters: quickFiltersRule, linkFilter: resolvedFilter, search: searchVal })
            return
        }
        fetchViewData({
            quickFilters: quickFiltersRule,
            linkFilter: resolvedFilter,
            sorts: sortCache,
            tab: tabValue,
            search: searchVal,
            pageNum: 1
        })
    })


    // useUpdateEffect(() => {
    //     reloadData({ groupCache, quickFilters: quickFiltersRule, search: searchVal, pageNum: 1 })
    // }, [currentRecordId])

    // BLOCK  设置中 分组字段变动
    useUpdateEffect(() => {
        getGroupRecordCount({ groupConfigure, quickFilters: quickFiltersRule, linkFilter: resolvedFilter, search: searchVal })
    }, [groupConfigure?.groupByFieldId])

    // 选中的分组被隐藏
    useUpdateEffect(() => {
        if (!tabCountList || tabCountList.length === 0) {
            return
        }
        const list = initTabList.reduce<GroupTab[]>((list, item) => {
            const data = find(v => v.value === item.value, tabCountList)
            const person = find(v => v.userId === item.value, personOptions)
            if (person?.isDepart) {
                return list
            }
            if (groupConfigure?.canHiddenTabValue && data?.count === 0) {
                return list
            }
            list.push({
                label: person?.isDepart ? `${item.label}(已注销)` : item.label,
                value: item.value,
                count: data?.count
            })
            return list
        }, [])
        if(!equal(tabList, list) || groupByFieldIdRef.current !== groupConfigure?.groupByFieldId || list.length === 0){
            groupByFieldIdRef.current = groupConfigure?.groupByFieldId
            setTabList(list)
        }
    }, [initTabList, tabCountList, groupConfigure?.canHiddenTabValue])


     // 选中的分组已不再分组列表中,进行切换
     useUpdateEffect(() => {
        const isExist = find(v => v.value === tabValue, tabList)
        const firstTab = tabList?.[0]?.value || ''
        if (groupConfigure?.groupByFieldId) {
            const tab = tabValue || firstTab
            setTabValue(tab)
            fetchViewData({
                quickFilters: quickFiltersRule,
                linkFilter: resolvedFilter,
                sorts: sortCache,
                tab,
                search: searchVal,
                pageNum: 1
            })
            return
        }

        if (!isExist && firstTab !== tabValue) {
            setTabValue(firstTab)
            fetchViewData({
                quickFilters: quickFiltersRule,
                linkFilter: resolvedFilter,
                sorts: sortCache,
                tab: firstTab,
                search: searchVal,
                pageNum: 1
            })
        }

        if (tabList.length === 0 && tabCountList && tabCountList?.length > 0) {
            fetchViewData({
                quickFilters: quickFiltersRule,
                linkFilter: resolvedFilter,
                sorts: sortCache,
                tab: '',
                search: searchVal,
                pageNum: 1
            })
        }
    }, [tabList, groupConfigure?.groupByFieldId])

    return { reloadData, resolvedFilter }
}
