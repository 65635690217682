import type {
    DataSourceAbstract,
    Field,
    FieldBlockAbstract,
    FieldType,
    FilterBlockAbstract,
    FilterWay,
    PageVariableType,
    SystemVariable,
    SystemVariableValue,
    TypeInstanceMap,
    VariableADTvalue,
    VariableFieldADTValue,
    ViewType
} from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import { filterObjectUndefined } from '@lighthouse/tools'
import { find, reduce } from 'rambda'

import {
    datasourceFieldTypes,
    defaultFormat,
    FieldIconTypeMap,
    fieldValueSettingMap,
    InnerTypeMapByFieldType,
    innerTypeToFieldTypeIconMap,
    innerTypeToFieldTypeMapInCondition,
    nodeTypeIconMap,
    noRealFieldTypes,
    USER_DATASOURCE,
    variableSystemNameMap,
    VIEW_BLOCK_NAME_MAP,
    VIEW_ICON_MAP
} from '../../constants'
import { FIELD_BLOCK_ICON_MAP } from '../../constants/inputField'
import type { AggregateResultField, AggregatorNode, FlowNode, SubProcessVariable, VariableSource, VariableTriggerConfig } from '../../types'
import { AggregatorNodeType, VariableSourceType } from '../../types'
import { getFieldIcon, getNodeDataSourceId, getRealField, getTableIcon, geyAggregatorNodeIcon } from '../../utils'
import { CURRENT_PAGE, DEFAULT_FILTER_VALUE_VARIABLE, sourceDescriptionMap } from './constant'
import type {
    AggregatorUpstreamOptionParams,
    FormOptionParams,
    getJoinOptionParams,
    VariableChildren,
    VariableOptions,
    VariableTree
} from './types'

export const getMultipleValue = function (list: string[], current: string) {
    return list.includes(current) ? list.filter(item => item !== current) : [...list, current]
}

export const getVariableItem = function (options: VariableTree[], value?: VariableADTvalue) {
    if (!value) {
        return
    }
    switch (value.type) {
        case VariableType.ARG_VARIABLE: {
            const parentNode = find(item => item.id === value?.argVariable?.nodeId && item.type === VariableType.ARG_VARIABLE, options)
            const childNode = find(item => item.id === value?.argVariable?.argId, parentNode?.children ?? [])
            return [parentNode, childNode]
        }
        case VariableType.UPSTREAM: {
            const parentNode = find(item => item.id === value?.upstreamVariable?.nodeId && item.type === VariableType.UPSTREAM, options)
            const childNode = find(item => item.id === value?.upstreamVariable?.fieldId, parentNode?.children ?? [])
            return [parentNode, childNode]
        }
        case VariableType.VARIABLE: {
            const parentNode = find(item => item.id === value?.fieldVariable?.dsId && item.type === VariableType.VARIABLE, options)
            const childNode = find(item => item.id === value?.fieldVariable?.fieldId, parentNode?.children ?? [])
            return [parentNode, childNode]
        }
        case VariableType.PAGE: {
            return reduce<VariableTree, [VariableTree | undefined, VariableChildren | undefined]>(
                (prev, parentNode) => {
                    const childNode = find(
                        item => item.id === value?.pageVariable?.fieldId && value?.pageVariable?.type === parentNode.sourceType,
                        parentNode?.children ?? []
                    )
                    if (childNode) {
                        return [parentNode, childNode]
                    }
                    return prev
                },
                [undefined, undefined],
                options
            )
        }
        default: {
            return []
        }
    }
}

export const getVariableValue = function (parentNode: VariableTree, childNode?: VariableChildren): VariableADTvalue {
    switch (parentNode.type) {
        case VariableType.UPSTREAM: {
            return { type: parentNode.type, upstreamVariable: { nodeId: parentNode.id, fieldId: childNode?.id } }
        }
        case VariableType.ARG_VARIABLE: {
            return { type: parentNode.type, argVariable: { nodeId: parentNode.id, argId: childNode?.id } }
        }
        case VariableType.VARIABLE: {
            return { type: parentNode.type, fieldVariable: { fieldId: childNode?.id, dsId: parentNode.dsId ?? '' } }
        }
        case VariableType.PAGE: {
            return {
                type: parentNode.type,
                pageVariable: { type: parentNode?.sourceType as PageVariableType, fieldId: childNode?.id, dsId: parentNode.dsId ?? '' }
            }
        }
        case VariableType.FIELD_ID: {
            return { type: parentNode.type, fieldIdVariable: { fieldId: childNode?.id } }
        }
        case VariableType.USER: {
            return { type: parentNode.type, userVariable: { fieldId: childNode?.id } }
        }
        case VariableType.SYSTEM: {
            return { type: parentNode.type, systemVariable: { value: childNode?.id as SystemVariableValue } }
        }
        case VariableType.INPUT: {
            return { type: parentNode.type, inputVariable: { blockId: childNode?.id ?? '' } }
        }
        case VariableType.FORM: {
            return { type: parentNode.type, formVariable: { fieldId: childNode?.id } }
        }
        case VariableType.VIEW: {
            return { type: parentNode.type, viewVariable: { fieldId: childNode?.id ?? '' } }
        }
        case VariableType.PAGE_LINK: {
            return { type: parentNode.type, pageLinkVariable: { value: CURRENT_PAGE } }
        }
        case VariableType.FILTER: {
            return { type: parentNode.type, filterVariable: { itemId: childNode?.id ?? '', blockId: parentNode.id } }
        }
        default: {
            return DEFAULT_FILTER_VALUE_VARIABLE
        }
    }
}

export const isVariableItemActive = function (option: VariableTree, value?: VariableADTvalue): boolean {
    if (!value) {
        return false
    }
    switch (value.type) {
        case VariableType.UPSTREAM: {
            return option.id === value.upstreamVariable?.nodeId && option.type === VariableType.UPSTREAM
        }
        case VariableType.VARIABLE: {
            return option.id === value.fieldVariable?.dsId && option.type === VariableType.VARIABLE
        }
        case VariableType.PAGE: {
            return (
                option.id === value.pageVariable?.dsId && option.type === VariableType.PAGE && option.sourceType === value.pageVariable.type
            )
        }

        case VariableType.FIELD_ID: {
            return Boolean(value.fieldIdVariable) && option.type === VariableType.FIELD_ID
        }
        case VariableType.USER: {
            return Boolean(value.userVariable) && option.type === VariableType.USER
        }
        case VariableType.INPUT: {
            return Boolean(value.inputVariable) && option.type === VariableType.INPUT
        }
        case VariableType.SYSTEM: {
            return Boolean(value.systemVariable) && option.type === VariableType.SYSTEM
        }
        case VariableType.FORM: {
            return Boolean(value.formVariable) && option.type === VariableType.FORM
        }
        case VariableType.VIEW: {
            return Boolean(value.viewVariable) && option.type === VariableType.VIEW
        }
        case VariableType.PAGE_LINK: {
            return Boolean(value.pageLinkVariable) && option.type === VariableType.PAGE_LINK && option.id === value.pageLinkVariable?.value
        }
        case VariableType.FILTER: {
            return Boolean(value.filterVariable) && option.type === VariableType.FILTER && option.id === value.filterVariable?.blockId
        }
        case VariableType.SELECT_DATASOURCE: {
            return (
                Boolean(value.selectDataSourceVariable) &&
                option.type === VariableType.SELECT_DATASOURCE &&
                option.id === value.selectDataSourceVariable?.filter
            )
        }
        default: {
            return false
        }
    }
}
export const isVariableChildrenItemActive = function (option?: VariableChildren, value?: VariableADTvalue): boolean {
    if (!value) {
        return false
    }
    switch (value.type) {
        case VariableType.UPSTREAM: {
            return option?.id === value.upstreamVariable?.fieldId
        }
        case VariableType.VARIABLE: {
            return option?.id === value.fieldVariable?.fieldId
        }
        case VariableType.PAGE: {
            return option?.id === value.pageVariable?.fieldId
        }
        case VariableType.FIELD_ID: {
            return option?.id === value.fieldIdVariable?.fieldId
        }
        case VariableType.USER: {
            return option?.id === value.userVariable?.fieldId
        }
        case VariableType.INPUT: {
            return option?.id === value.inputVariable?.blockId
        }
        case VariableType.SYSTEM: {
            return option?.id === value.systemVariable?.value
        }
        case VariableType.FORM: {
            return option?.id === value.formVariable?.fieldId
        }
        case VariableType.VIEW: {
            return option?.id === value.viewVariable?.fieldId
        }
        case VariableType.PAGE_LINK: {
            return true
        }
        case VariableType.FILTER: {
            return option?.id === value.filterVariable?.itemId
        }
        default: {
            return false
        }
    }
}

export const getDefaultDataSourceOptions = function (
    dataSource?: DataSourceAbstract,
    validateFieldType?: (innerType: TypeInstanceMap) => boolean
): VariableTree | undefined {
    if (!dataSource) {
        return
    }
    return {
        id: dataSource.id,
        icon: getTableIcon(dataSource),
        title: dataSource.name,
        dsId: dataSource.id,
        type: VariableType.FIELD_ID,
        description: '当前表',
        enableSearch: true,
        disabledList: validateFieldType
            ? datasourceFieldTypes.filter(type => {
                const innerType = InnerTypeMapByFieldType[type]
                return !validateFieldType(innerType)
            })
            : [],
        children: reduce<[string, Field], VariableChildren[]>(
            (preVal, [fieldId, field]) => {
                if (!validateFieldType || validateFieldType(field.innerType)) {
                    return [
                        ...preVal,
                        {
                            icon: FieldIconTypeMap[field.type],
                            title: field.name,
                            id: fieldId,
                            fieldType: field.type,
                            fieldInnerType: field.innerType,
                            type: VariableType.FIELD_ID
                        }
                    ]
                }
                return preVal
            },
            [],
            Object.entries(dataSource.schema)
        )
    }
}

type GetValueOptionPayload = {
    source: VariableSource
    inputType?: FieldType
    validateFieldType?: (type: FieldType) => boolean
}

export const getValueOption = (payload: GetValueOptionPayload) => {
    const { source, inputType, validateFieldType } = payload
    // 上游节点
    const disabledList = validateFieldType ? datasourceFieldTypes.filter(type => type !== 'formula' && !validateFieldType(type)) : []
    if (source.sourceType === VariableSourceType.parentNode || source.sourceType === VariableSourceType.form) {
        const { sourceType, parentNodes, dataSourceList } = source
        const list = parentNodes.reduce<VariableTree[]>((preVal, node) => {
            if (node.data.nodeType === 'ARG_TRIGGER' && node.data.config) {
                const config = node.data.config as VariableTriggerConfig
                const { args = [] } = config
                const subProcessOption: VariableTree = {
                    id: node.id,
                    icon: nodeTypeIconMap[node.data.nodeType],
                    dsId: '',
                    type: VariableType.ARG_VARIABLE,
                    title: node.data.name,
                    description: '',
                    enableSearch: true,
                    disabledList,
                    children: reduce<SubProcessVariable, VariableChildren[]>((preVal, arg) => {
                        const fieldType = innerTypeToFieldTypeMapInCondition[arg.innerType]
                        if (!validateFieldType || (fieldType && validateFieldType(fieldType))) {
                            preVal.push({
                                icon: innerTypeToFieldTypeIconMap[arg.innerType],
                                title: arg.name,
                                fieldInnerType: arg.innerType,
                                id: arg.id
                            })
                            return preVal
                        }

                        return preVal
                    }, [], args)
                }
                return [...preVal, subProcessOption]
            }

            const dataSourceId = getNodeDataSourceId(node, parentNodes)
            if (!dataSourceId) {
                return preVal
            }

            const ds = find(item => item.id === dataSourceId, dataSourceList)
            if (!ds) {
                return preVal
            }
            const dsOption: VariableTree = {
                id: node.id,
                icon: nodeTypeIconMap[node.data.nodeType],
                dsId: ds.id,
                type: VariableType.UPSTREAM,
                title: node.data.name,
                description: ds.name,
                enableSearch: true,
                disabledList,
                children: reduce<[string, Field], VariableChildren[]>(
                    (preVal, [fieldId, field]) => {
                        if (!validateFieldType) {
                            preVal.push({
                                icon: FieldIconTypeMap[field.type],
                                title: field.name,
                                fieldType: field.type,
                                fieldInnerType: field.innerType,
                                id: fieldId
                            })
                            return preVal
                        }
                        if (noRealFieldTypes.has(field.type)) {
                            if (!field.innerType) {
                                return preVal
                            }
                            if (validateFieldType(field.type)) {
                                preVal.push({
                                    icon: getFieldIcon(field.id, field.type, field.innerType),
                                    title: field.name,
                                    fieldType: field.type,
                                    fieldInnerType: field.innerType,
                                    id: fieldId
                                })
                                return preVal
                            }
                            return preVal
                        }
                        if (validateFieldType(field.type)) {
                            preVal.push({
                                icon: FieldIconTypeMap[field.type],
                                title: field.name,
                                fieldType: field.type,
                                fieldInnerType: field.innerType,
                                id: fieldId
                            })
                        }

                        return preVal
                    },
                    [],
                    Object.entries(ds.schema)
                )
            }
            return [...preVal, dsOption]
        }, [])
        return { type: sourceType, list }
    }
    // 页面
    const { dataSource, sourceType } = source

    if (!dataSource) {
        return { type: sourceType, list: [] }
    }
    const defaultFilter = (fieldType: FieldType) => !inputType || fieldValueSettingMap[inputType].includes(fieldType)
    const filter = validateFieldType ?? defaultFilter
    const item = {
        id: dataSource.id,
        icon: getTableIcon(dataSource),
        title: sourceDescriptionMap[sourceType],
        dsId: dataSource.id,
        type: VariableType.PAGE,
        // description: `${sourceDescriptionMap[sourceType]}: ${page?.name || '未命名页面'}`,
        enableSearch: true,
        disabledList,
        sourceType,
        children: reduce<[string, Field], VariableChildren[]>(
            (preVal, [fieldId, field]) => {
                if (noRealFieldTypes.has(field.type)) {
                    if (!field.innerType) {
                        return preVal
                    }

                    if (filter(field.type)) {
                        preVal.push({
                            icon: getFieldIcon(field.id, field.type, field.innerType),
                            title: field.name,
                            fieldType: field.type,
                            fieldInnerType: field.innerType,
                            id: fieldId
                        })
                        return preVal
                    }
                }

                if (filter(field.type)) {
                    preVal.push({
                        icon: getFieldIcon(field.id, field.type, field.innerType),
                        title: field.name,
                        fieldType: field.type,
                        fieldInnerType: field.innerType,
                        id: fieldId
                    })
                }
                return preVal
            },
            [],
            Object.entries(dataSource.schema)
        )
    }
    return { type: sourceType, list: [item] }
}

// 上游节点和页面 option 统一处理
export const getValueOptionByInnerType = (source: VariableSource, validateInnerType?: (innerType: TypeInstanceMap) => boolean) => {
    // 上游节点
    if (source.sourceType === VariableSourceType.parentNode || source.sourceType === VariableSourceType.form) {
        const { sourceType, parentNodes, dataSourceList } = source
        const list = parentNodes.reduce<VariableTree[]>((preVal, node) => {

            if (node.data.nodeType === 'ARG_TRIGGER' && node.data.config) {
                const config = node.data.config as VariableTriggerConfig
                const { args = [] } = config
                const subProcessOption: VariableTree = {
                    id: node.id,
                    icon: nodeTypeIconMap[node.data.nodeType],
                    dsId: '',
                    type: VariableType.ARG_VARIABLE,
                    title: node.data.name,
                    description: '',
                    enableSearch: true,
                    disabledList: validateInnerType
                        ? datasourceFieldTypes.filter(type => {
                            const innerType = InnerTypeMapByFieldType[type]
                            return !validateInnerType(innerType)
                        })
                        : [],
                    children: reduce<SubProcessVariable, VariableChildren[]>((preVal, arg) => {
                        if (!validateInnerType || validateInnerType(arg.innerType)) {
                            preVal.push({
                                icon: innerTypeToFieldTypeIconMap[arg.innerType],
                                title: arg.name,
                                fieldInnerType: arg.innerType,
                                id: arg.id
                            })
                            return preVal
                        }

                        return preVal
                    }, [], args)
                }
                return [...preVal, subProcessOption]
            }

            const dataSourceId = getNodeDataSourceId(node, parentNodes)
            if (!dataSourceId) {
                return preVal
            }

            const ds = find(item => item.id === dataSourceId, dataSourceList)
            if (!ds) {
                return preVal
            }
            const dsOption: VariableTree = {
                id: node.id,
                icon: nodeTypeIconMap[node.data.nodeType],
                dsId: ds.id,
                type: VariableType.UPSTREAM,
                title: node.data.name,
                description: ds.name,
                enableSearch: true,
                disabledList: validateInnerType
                    ? datasourceFieldTypes.filter(type => {
                        const innerType = InnerTypeMapByFieldType[type]
                        return !validateInnerType(innerType)
                    })
                    : [],
                children: reduce<[string, Field], VariableChildren[]>(
                    (preVal, [fieldId, field]) => {
                        if (!validateInnerType) {
                            preVal.push({
                                icon: getFieldIcon(field.id, field.type, field.innerType),
                                title: field.name,
                                fieldType: field.type,
                                fieldInnerType: field.innerType,
                                id: fieldId
                            })
                            return preVal
                        }
                        if (validateInnerType(field.innerType)) {
                            preVal.push({
                                icon: getFieldIcon(field.id, field.type, field.innerType),
                                title: field.name,
                                fieldType: field.type,
                                fieldInnerType: field.innerType,
                                id: fieldId
                            })
                        }

                        return preVal
                    },
                    [],
                    Object.entries(ds.schema)
                )
            }
            return [...preVal, dsOption]
        }, [])
        return { type: sourceType, list }
    }
    // 页面
    const { dataSource, sourceType } = source
    if (!dataSource) {
        return { type: sourceType, list: [] }
    }
    const item = {
        id: dataSource.id,
        icon: getTableIcon(dataSource),
        title: sourceDescriptionMap[sourceType],
        dsId: dataSource.id,
        type: VariableType.PAGE,
        // description: `${sourceDescriptionMap[sourceType]}: ${page?.name || '未命名页面'}`,
        enableSearch: true,
        sourceType,
        disabledList: validateInnerType
            ? datasourceFieldTypes.filter(type => {
                const innerType = InnerTypeMapByFieldType[type]
                return !validateInnerType(innerType)
            })
            : [],
        children: reduce<[string, Field], VariableChildren[]>(
            (preVal, [fieldId, field]) => {
                if (!field.innerType) {
                    return preVal
                }
                if (!validateInnerType || validateInnerType(field.innerType)) {
                    preVal.push({
                        icon: getFieldIcon(field.id, field.type, field.innerType),
                        title: field.name,
                        fieldType: field.type,
                        fieldInnerType: field.innerType,
                        id: fieldId
                    })
                }
                return preVal
            },
            [],
            Object.entries(dataSource.schema)
        )
    }
    return { type: sourceType, list: [item] }
}

export const getDefaultValueOptions = function (params: {
    sources?: VariableSource[]
    inputType?: FieldType
    validateFieldType?: (fieldType: FieldType) => boolean
}): VariableOptions {
    const { sources, inputType, validateFieldType } = params
    if (!sources) {
        return []
    }
    return sources.map(source => {
        return getValueOption({ source, inputType, validateFieldType })
    })
}

export const getDefaultValueOptionsByInnerType = function (params: {
    sources?: VariableSource[]
    validateInnerType?: (innerType: TypeInstanceMap) => boolean
}): VariableOptions {
    const { sources, validateInnerType } = params
    if (!sources) {
        return []
    }
    return sources.map(source => {
        return getValueOptionByInnerType(source, validateInnerType)
    })
}

const getAggregatorUpstreamDescription = (node: AggregatorNode, dataSourceList: DataSourceAbstract[]) => {
    if (node?.type === AggregatorNodeType.DATA_INPUT) {
        const { dsId } = node.data.config
        const dataSource = find(item => item.id === dsId, dataSourceList)
        return dataSource?.name
    }
    return ''
}

// 获取聚合表上游节点
export const getAggregatorUpstreamOption = ({
    aggregateNodeList,
    dataSourceList,
    validateFieldType
}: AggregatorUpstreamOptionParams): VariableOptions => {
    return [
        {
            type: VariableSourceType.parentJoinNode,
            list: aggregateNodeList.reduce<VariableTree[]>((preVal, node) => {
                const {
                    id,
                    data: {
                        result,
                        config: { name }
                    },
                    type
                } = node
                const fieldList = result?.fieldList || []
                const description = getAggregatorUpstreamDescription(node, dataSourceList)
                const dsOption: VariableTree = {
                    id,
                    icon: geyAggregatorNodeIcon(node),
                    dsId: id,
                    type: VariableType.UPSTREAM,
                    title: name,
                    description,
                    enableSearch: true,
                    disabledList: [],
                    children: reduce<AggregateResultField, VariableChildren[]>(
                        (preVal, field) => {
                            if (!validateFieldType) {
                                preVal.push({
                                    icon: getFieldIcon(field.id, field.type, field.innerType),
                                    title: field.name,
                                    fieldType: field.type,
                                    fieldInnerType: field.innerType,
                                    id: field.id
                                })
                                return preVal
                            }
                            if (!field.innerType) {
                                return preVal
                            }
                            if (validateFieldType(field.innerType)) {
                                preVal.push({
                                    icon: getFieldIcon(field.id, field.type, field.innerType),
                                    title: field.name,
                                    fieldType: field.type,
                                    fieldInnerType: field.innerType,
                                    id: field.id
                                })
                                return preVal
                            }

                            return preVal
                        },
                        [],
                        fieldList
                    )
                }
                return [...preVal, dsOption]
            }, [])
        }
    ]
}

// 获取连接表上游连接的数据源
export const getJoinOption = ({ primaryDsId, dataSourceList, validateField }: getJoinOptionParams): VariableOptions => {
    return [
        {
            type: VariableSourceType.parentJoinNode,
            list: dataSourceList.reduce<VariableTree[]>((preVal, ds) => {
                const { id, schema } = ds
                const fieldList = Object.values(schema)
                const dsOption: VariableTree = {
                    id,
                    icon: getTableIcon(ds),
                    dsId: id,
                    type: VariableType.VARIABLE,
                    title: ds.name,
                    description: primaryDsId === id ? '主表' : '连接表',
                    enableSearch: true,
                    disabledList: [],
                    children: reduce<Field, VariableChildren[]>(
                        (preVal, field) => {
                            if (!validateField) {
                                preVal.push({
                                    icon: getFieldIcon(field.id, field.type, field.innerType),
                                    title: field.name,
                                    fieldType: field.type,
                                    fieldInnerType: field.innerType,
                                    id: field.id
                                })
                                return preVal
                            }
                            if (!field.innerType) {
                                return preVal
                            }
                            if (validateField(field)) {
                                preVal.push({
                                    icon: getFieldIcon(field.id, field.type, field.innerType),
                                    title: field.name,
                                    fieldType: field.type,
                                    fieldInnerType: field.innerType,
                                    id: field.id
                                })
                                return preVal
                            }

                            return preVal
                        },
                        [],
                        fieldList
                    )
                }
                return [...preVal, dsOption]
            }, [])
        }
    ]
}

/** 获取页面数据options */
export const getPageDataOptions = (
    params: {
        datasource: DataSourceAbstract
        variableSourceType: VariableSourceType.page | VariableSourceType.parentPage
        // pageName: string
        validateFieldType?: (field: Field) => boolean
    }[]
): VariableOptions => {
    return params.map(item => {
        const { datasource, variableSourceType, validateFieldType } = item
        return {
            type: variableSourceType,
            list: [
                {
                    id: datasource.id,
                    icon: 'BlockTable',
                    title: variableSourceType === VariableSourceType.page ? '当前页面' : '上游页面',
                    // description: `${}: ${pageName}`,
                    enableSearch: true,
                    dsId: datasource.id,
                    type: VariableType.PAGE,
                    sourceType: variableSourceType,
                    children: Object.values(datasource.schema).reduce<VariableChildren[]>((prev, field) => {
                        const realField = getRealField(field)
                        if (!realField) {
                            return prev
                        }
                        if (!validateFieldType || validateFieldType(realField)) {
                            prev.push({
                                id: realField.id,
                                icon: getFieldIcon(realField.id, realField.type, realField.innerType),
                                title: realField.name,
                                fieldType: field.type,
                                fieldInnerType: field.innerType,
                                type: VariableType.PAGE
                            })
                        }
                        return prev
                    }, [])
                }
            ]
        }
    })
}

export const getUserDatasourceOptions = function (params: {
    dataSource?: DataSourceAbstract
    validateFieldType?: (field: Field) => boolean
}): VariableTree | undefined {
    const { dataSource, validateFieldType } = params
    if (!dataSource) {
        return
    }
    const fieldList = Object.entries(dataSource.schema)
    const alreadyFieldType = Object.entries(
        reduce<[string, Field], Record<string, boolean>>(
            (prev, [fieldId, field]) => {
                if (validateFieldType) {
                    if (!validateFieldType(field)) {
                        prev[field.type] = true
                    }
                    return prev
                }
                return prev
            },
            {},
            fieldList
        )
    ).map(([fieldType]) => fieldType) as FieldType[]
    return {
        id: dataSource.id,
        icon: getTableIcon(dataSource),
        title: '当前登录用户数据',
        dsId: dataSource.id,
        type: VariableType.USER,
        enableSearch: true,
        disabledList: datasourceFieldTypes.filter(type => {
            return alreadyFieldType.includes(type)
        }),
        children: reduce<[string, Field], VariableChildren[]>(
            (preVal, [fieldId, field]) => {
                const realField = getRealField(field)
                if (!realField) {
                    return preVal
                }
                if (!validateFieldType) {
                    preVal.push({
                        icon: getFieldIcon(realField.id, realField.type, realField.innerType),
                        title: realField.name,
                        id: fieldId,
                        fieldType: field.type,
                        fieldInnerType: field.innerType,
                        type: VariableType.USER
                    })
                    return preVal
                }
                if (noRealFieldTypes.has(realField.type)) {
                    if (!realField.innerType) {
                        return preVal
                    }
                    // const fieldRealType = innerTypeToFieldMap[realField.innerType]
                    // if (!fieldRealType) {
                    //     return preVal
                    // }
                    if (validateFieldType(realField)) {
                        preVal.push({
                            icon: getFieldIcon(realField.id, realField.type, realField.innerType),
                            title: realField.name,
                            id: fieldId,
                            fieldType: field.type,
                            fieldInnerType: field.innerType,
                            type: VariableType.USER
                        })
                        return preVal
                    }
                    return preVal
                }
                if (validateFieldType(realField)) {
                    return [
                        ...preVal,
                        {
                            icon: getFieldIcon(realField.id, realField.type, realField.innerType),
                            title: realField.name,
                            id: fieldId,
                            fieldType: field.type,
                            type: VariableType.USER
                        }
                    ]
                }
                return preVal
            },
            [],
            fieldList
        )
    }
}

export const getUserDatasourceOptionsByInnerType = function (params: {
    dataSource?: DataSourceAbstract
    validateFieldType?: (innerType: TypeInstanceMap) => boolean
}): VariableTree | undefined {
    const { dataSource, validateFieldType } = params
    if (!dataSource) {
        return
    }
    return {
        id: dataSource.id,
        icon: getTableIcon(dataSource),
        title: '当前登录用户数据',
        dsId: dataSource.id,
        type: VariableType.USER,
        enableSearch: true,
        disabledList: validateFieldType
            ? datasourceFieldTypes.filter(type => {
                const innerType = InnerTypeMapByFieldType[type]
                return !validateFieldType(innerType)
            })
            : [],
        children: reduce<[string, Field], VariableChildren[]>(
            (preVal, [fieldId, field]) => {
                if (!validateFieldType) {
                    preVal.push({
                        icon: getFieldIcon(field.id, field.type, field.innerType),
                        title: field.name,
                        id: fieldId,
                        fieldType: field.type,
                        fieldInnerType: field.innerType,
                        type: VariableType.USER
                    })
                    return preVal
                }
                if (validateFieldType(field.innerType)) {
                    return [
                        ...preVal,
                        {
                            icon: getFieldIcon(field.id, field.type, field.innerType),
                            title: field.name,
                            id: fieldId,
                            fieldType: field.type,
                            fieldInnerType: field.innerType,
                            type: VariableType.USER
                        }
                    ]
                }
                return preVal
            },
            [],
            Object.entries(dataSource.schema)
        )
    }
}

export const getSystemOption = function (options: SystemVariableValue[]): VariableTree {
    return {
        id: 'SYSTEM_VARIABLE',
        icon: FieldIconTypeMap['date'],
        title: '常用日期',
        dsId: '',
        type: VariableType.SYSTEM,
        children: options.map(item => ({
            icon: '',
            title: variableSystemNameMap[item],
            id: item,
            type: VariableType.SYSTEM
        }))
    }
}

export const optionFilterFieldType: (
    allDataSource: DataSourceAbstract[],
    options: VariableOptions,
    validateFieldType?: (field: Field) => boolean
) => VariableOptions = (allDataSource, options, validateFieldType) =>
        options.map(option => {
            const newList = option.list.map(item => {
                const { dsId, children } = item
                const dataSource = find(ds => ds.id === dsId, allDataSource)
                if (!dataSource) {
                    return item
                }
                const { schema } = dataSource

                const newChildren = children.reduce<VariableChildren[]>((prev, cur) => {
                    const field = schema[cur.id]
                    if (!field) {
                        return prev
                    }
                    const realField = getRealField(field)
                    if (!realField) {
                        return prev
                    }
                    if (!validateFieldType || validateFieldType(realField)) {
                        prev.push({
                            ...cur,
                            icon: getFieldIcon(realField.id, realField.type, realField.innerType)
                        })
                    }
                    return prev
                }, [])
                return {
                    ...item,
                    children: newChildren
                }
            })
            return {
                ...option,
                list: newList
            }
        })

export const getInputOption = function (
    fieldBlocks: FieldBlockAbstract[],
    event?: {
        validateFieldType?: (fieldBlock: FieldBlockAbstract) => boolean
        onMouseEnter?: (blockId: string) => void
        onMouseLeave?: (blockId: string) => void
    }
): VariableTree | undefined {
    const { validateFieldType, onMouseEnter, onMouseLeave } = event || {}
    return {
        id: 'INPUT_VARIABLE',
        icon: 'InputIcon',
        title: '输入框',
        dsId: '',
        type: VariableType.INPUT,
        enableSearch: true,
        children: reduce<FieldBlockAbstract, VariableChildren[]>(
            (preVal, fieldBlock) => {
                const {
                    id,
                    title,
                    config: { inputType }
                } = fieldBlock
                // fieldBlock.config.fi
                // if (inputType === 'file' || inputType === 'notes') {
                if (!validateFieldType || validateFieldType(fieldBlock)) {
                    return [
                        ...preVal,
                        {
                            icon: FIELD_BLOCK_ICON_MAP[inputType],
                            title: title ?? '',
                            // fieldType: field?.type,
                            onMouseEnter: () => onMouseEnter?.(id),
                            onMouseLeave: () => onMouseLeave?.(id),
                            id,
                            type: VariableType.INPUT
                        }
                    ]
                }
                return preVal
            },
            [],
            fieldBlocks
        )
    }
}

/** 视图的变量选项 */
export const getViewOptions = (params: {
    dataSource?: DataSourceAbstract
    viewType?: ViewType
    validateFieldType?: (field: Field) => boolean
}): VariableTree | undefined => {
    const { dataSource, viewType, validateFieldType } = params
    if (!dataSource || !viewType) {
        return
    }
    return {
        id: 'VIEW_VARIABLE',
        icon: VIEW_ICON_MAP[viewType],
        title: viewType === 'custom' ? VIEW_BLOCK_NAME_MAP[viewType] : `${VIEW_BLOCK_NAME_MAP[viewType]}视图`,
        dsId: dataSource?.id,
        enableSearch: true,
        type: VariableType.VIEW,
        children: Object.values(dataSource.schema).reduce<VariableChildren[]>((prev, field) => {
            const realField = getRealField(field)
            if (!realField) {
                return prev
            }
            if (!validateFieldType || validateFieldType(realField)) {
                prev.push({
                    id: realField.id,
                    icon: getFieldIcon(realField.id, realField.type, realField.innerType),
                    title: realField.name,
                    fieldType: field.type,
                    fieldInnerType: field.innerType,
                    type: VariableType.VIEW
                })
            }
            return prev
        }, [])
    }
}

export const getEmptyFieldValue = function (type: FieldType): VariableFieldADTValue {
    switch (type) {
        case 'textGeneration':
        case 'number':
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'email':
        case 'userGroup':
        case 'id':
        case 'date': {
            return { type, value: '', format: defaultFormat }
        }
        case 'selectGenerationByText':
        case 'select':
        case 'person':
        case 'video':
        case 'file': {
            return { type, value: [] }
        }
        default: {
            return { type: 'text', value: '' }
        }
    }
}

export const getSystemVariableValue = (variable: SystemVariable) => {
    if (!variable.systemVariable?.value) {
        return ''
    }
    const value = variable.systemVariable?.value

    switch (value) {
        case 'TOMORROW': {
            const tomorrow = new Date()
            tomorrow.setDate(tomorrow.getDate() + 1)

            return tomorrow.valueOf()
        }
        case 'NOW':
        case 'TODAY': {
            return Date.now()
        }
        case 'YESTERDAY': {
            const yesterday = new Date()
            yesterday.setDate(yesterday.getDate() - 1)

            return yesterday.valueOf()
        }
        default: {
            return ''
        }
    }
}

export const getDefaultFormOption = ({
    dataSourceList,
    validateFieldType,
}: FormOptionParams): VariableOptions => {
    return [
        {
            type: VariableSourceType.form,
            list: dataSourceList.reduce<VariableTree[]>((preVal, ds) => {
                if (!ds) {
                    return preVal
                }
                const dsOption: VariableTree = {
                    id: ds.id,
                    icon: getTableIcon(ds),
                    dsId: ds.id,
                    type: VariableType.FORM,
                    title: '表单创建的数据',
                    enableSearch: true,
                    disabledList: validateFieldType ? datasourceFieldTypes.filter(type => !validateFieldType(type)) : [],
                    children: reduce<[string, Field], VariableChildren[]>(
                        (preVal, [fieldId, field]) => {
                            if (!validateFieldType) {
                                preVal.push({
                                    icon: getFieldIcon(field.id, field.type, field.innerType),
                                    title: field.name,
                                    fieldType: field.type,
                                    fieldInnerType: field.innerType,
                                    id: fieldId
                                })
                                return preVal
                            }
                            if (noRealFieldTypes.has(field.type)) {
                                if (!field.innerType) {
                                    return preVal
                                }

                                if (validateFieldType(field.type)) {
                                    preVal.push({
                                        icon: getFieldIcon(field.id, field.type, field.innerType),
                                        title: field.name,
                                        fieldType: field.type,
                                        fieldInnerType: field.innerType,
                                        id: fieldId
                                    })
                                    return preVal
                                }
                            }
                            if (validateFieldType(field.type)) {
                                preVal.push({
                                    icon: getFieldIcon(field.id, field.type, field.innerType),
                                    title: field.name,
                                    fieldType: field.type,
                                    fieldInnerType: field.innerType,
                                    id: fieldId
                                })
                            }

                            return preVal
                        },
                        [],
                        Object.entries(ds.schema)
                    )
                }
                return [...preVal, dsOption]
            }, [])
        }
    ]
}

// 获取表单的字段
export const getFormDatasourceOptions = function (params: {
    dataSource?: DataSourceAbstract
    validateFieldType?: (field: Field) => boolean
}): VariableTree | undefined {
    const { dataSource, validateFieldType } = params
    if (!dataSource) {
        return
    }
    const fieldList = Object.entries(dataSource.schema)
    const alreadyFieldType = Object.entries(
        reduce<[string, Field], Record<string, boolean>>(
            (prev, [fieldId, field]) => {
                if (validateFieldType) {
                    if (!validateFieldType(field)) {
                        prev[field.type] = true
                    }
                    return prev
                }
                return prev
            },
            {},
            fieldList
        )
    ).map(([fieldType]) => fieldType) as FieldType[]
    return {
        id: dataSource.id,
        icon: 'LayerFormContainerBlock',
        title: '表单容器',
        dsId: dataSource.id,
        type: VariableType.FORM,
        enableSearch: true,
        disabledList: datasourceFieldTypes.filter(type => {
            return !alreadyFieldType.includes(type)
        }),
        children: reduce<[string, Field], VariableChildren[]>(
            (preVal, [fieldId, field]) => {
                const realField = getRealField(field)
                if (!realField) {
                    return preVal
                }
                if (validateFieldType) {
                    if (validateFieldType(realField)) {
                        preVal.push({
                            icon: getFieldIcon(realField.id, realField.type, realField.innerType),
                            title: realField.name,
                            id: fieldId,
                            fieldType: field.type,
                            fieldInnerType: field.innerType,
                            type: VariableType.FORM
                        })
                    }
                    return preVal
                }
                preVal.push({
                    icon: getFieldIcon(realField.id, realField.type, realField.innerType),
                    title: realField.name,
                    id: fieldId,
                    fieldType: field.type,
                    fieldInnerType: field.innerType,
                    type: VariableType.FORM
                })
                return preVal
            },
            [],
            fieldList
        )
    }
}

export const getFilterOptions = (blocks: FilterBlockAbstract[], validateFilterType?: (type: FilterWay) => boolean): VariableTree[] => {
    return blocks.map(block => {
        return {
            id: block.id,
            icon: 'Filter',
            dsId: '',
            title: block.title,
            type: VariableType.FILTER,
            enableSearch: true,
            children: block.config.filterItems.reduce<VariableChildren[]>((prev, cur) => {
                if (validateFilterType && !validateFilterType(cur.filterWay)) {
                    return prev
                }
                prev.push({
                    icon: '',
                    title: cur.title,
                    id: cur.id,
                    type: VariableType.FILTER
                })
                return prev
            }, [])
        }
    })
}

type BaseParamOption = {
    validateField?: (field: Field) => boolean
}

// 生成所有类型的option 变量的方法
export const getAllOptions = function (params: {
    user?: BaseParamOption | boolean
    view?: BaseParamOption & { dsId?: string; viewType: ViewType }
    page?: BaseParamOption & { curDsId?: string; prevDsId?: string }
    system?: SystemVariableValue[]
    dataSourceList: DataSourceAbstract[]
    validateField?: (field: Field) => boolean
}) {
    const { user, view, page, system, dataSourceList, validateField } = params

    // 用户选项
    const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
    const userEnable = !!user
    const userOption = userEnable
        ? getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: (typeof user === 'boolean' ? undefined : user?.validateField) ?? validateField
        })
        : undefined

    // 视图选项
    const viewEnable = !!view
    const viewDataSource = find(item => item.id === view?.dsId, dataSourceList)
    const viewOption =
        viewEnable && viewDataSource
            ? getViewOptions({
                dataSource: viewDataSource,
                viewType: view.viewType,
                validateFieldType: view?.validateField ?? validateField
            })
            : undefined

    // 页面选项
    const pageEnable = !!page
    const pageCurDataSource = find(item => item.id === page?.curDsId, dataSourceList)
    const pagePrevDataSource = find(item => item.id === page?.prevDsId, dataSourceList)
    const configure = [
        pagePrevDataSource && {
            variableSourceType: VariableSourceType.parentPage,
            datasource: pagePrevDataSource,
            validateFieldType: page?.validateField ?? validateField
        },
        pageCurDataSource && {
            variableSourceType: VariableSourceType.page,
            datasource: pageCurDataSource,
            validateFieldType: page?.validateField ?? validateField
        }
    ].filter(filterObjectUndefined) as {
        datasource: DataSourceAbstract
        variableSourceType: VariableSourceType.page | VariableSourceType.parentPage
        validateFieldType?: ((field: Field) => boolean) | undefined
    }[]
    const pageOption = pageEnable ? getPageDataOptions(configure) : undefined

    const systemOption = system ? getSystemOption(system) : undefined

    return {
        userOption,
        viewOption,
        pageOption,
        systemOption
    }
}

export const getVariableText = (params: {
    value?: VariableADTvalue
    options?: VariableOptions
    userDsOption?: VariableTree
    inputOption?: VariableTree
    dataSourceOption?: VariableTree
    formOption?: VariableTree
    viewOption?: VariableTree
    filterOptions?: VariableTree[]
    dataSourceList?: DataSourceAbstract[]
}) => {
    const { value, options, userDsOption, inputOption, dataSourceOption, formOption, viewOption, filterOptions, dataSourceList } = params
    switch (value?.type) {
        case VariableType.SYSTEM: {
            if (value.systemVariable) {
                return `常用日期 / ${value.systemVariable?.value && variableSystemNameMap[value.systemVariable?.value]}`
            }
            return null
        }
        case VariableType.UPSTREAM: {
            const flatOptions = options?.flatMap(item => item.list)
            const [parentNode, childNode] = getVariableItem(flatOptions ?? [], value) ?? []
            if (parentNode && childNode) {
                return `${parentNode?.title} / ${childNode?.title}`
            }
            // return value?.upstreamVariable ? '找不到变量' : ''
            return '找不到变量'
        }
        case VariableType.VARIABLE:
        case VariableType.PAGE: {
            const flatOptions = options?.flatMap(item => item.list)
            const [parentNode, childNode] = getVariableItem(flatOptions ?? [], value) ?? []
            if (parentNode && childNode) {
                return `${parentNode.title} / ${childNode?.title}`
            }
            return '找不到变量'
        }
        case VariableType.USER: {
            const childNode = find(item => item.id === value.userVariable?.fieldId, userDsOption?.children ?? [])
            if (childNode) {
                return `${userDsOption?.title} / ${childNode?.title}`
            }
            return '找不到变量'
        }
        case VariableType.SELECT_DATASOURCE: {
            const childNode = find(item => item.id === value.selectDataSourceVariable?.dsId, dataSourceList ?? [])
            if (childNode) {
                return `查询数据表: ${childNode?.name}`
            }
            return '找不到变量'
        }
        case VariableType.FIELD_ID: {
            const childNode = find(item => item.id === value.fieldIdVariable?.fieldId, dataSourceOption?.children ?? [])
            if (childNode) {
                return `${childNode?.title}`
            }
            return '找不到变量'
        }
        case VariableType.INPUT: {
            const childNode = find(item => item.id === value.inputVariable?.blockId, inputOption?.children ?? [])
            if (childNode) {
                return `${inputOption?.title} / ${childNode.title}`
            }
            return '找不到变量'
        }
        case VariableType.FORM: {
            const childNode = find(item => item.id === value.formVariable?.fieldId, formOption?.children ?? [])
            if (childNode) {
                return `${formOption?.title} / ${childNode.title}`
            }
            return '找不到变量'
        }
        case VariableType.VIEW: {
            const childNode = find(item => item.id === value.viewVariable?.fieldId, viewOption?.children ?? [])
            if (childNode) {
                return `${viewOption?.title} / ${childNode.title}`
            }
            return '找不到变量'
        }
        case VariableType.PAGE_LINK: {
            return value.pageLinkVariable?.value === 'CURRENT_PAGE' ? '当前页面链接' : ''
        }
        case VariableType.FILTER: {
            const parentNode = find(item => item.id === value.filterVariable?.blockId, filterOptions ?? [])
            const childNode = find(item => item.id === value.filterVariable?.itemId, parentNode?.children ?? [])
            if (childNode) {
                return `${parentNode?.title} / ${childNode.title}`
            }
            return '找不到变量'
        }
        default: {
            return '找不到变量'
        }
    }
}


export const getUpstreamNodeOptionByInnerType = (params: {
    parentNodes: FlowNode[]
    allParentNodes: FlowNode[]
    dataSourceList: DataSourceAbstract[]
    validateInnerType?: (innerType: TypeInstanceMap) => boolean
}) => {
    const { parentNodes, dataSourceList, allParentNodes, validateInnerType } = params
    const list = parentNodes.reduce<VariableTree[]>((preVal, node) => {
        const dataSourceId = getNodeDataSourceId(node, allParentNodes)
        if (!dataSourceId) {
            return preVal
        }

        const ds = find(item => item.id === dataSourceId, dataSourceList)
        if (!ds) {
            return preVal
        }
        const dsOption: VariableTree = {
            id: node.id,
            icon: nodeTypeIconMap[node.data.nodeType],
            dsId: ds.id,
            type: VariableType.UPSTREAM,
            title: node.data.name,
            description: ds.name,
            enableSearch: true,
            disabledList: validateInnerType
                ? datasourceFieldTypes.filter(type => {
                    const innerType = InnerTypeMapByFieldType[type]
                    return !validateInnerType(innerType)
                })
                : [],
            children: reduce<[string, Field], VariableChildren[]>(
                (preVal, [fieldId, field]) => {
                    if (!validateInnerType) {
                        preVal.push({
                            icon: getFieldIcon(field.id, field.type, field.innerType),
                            title: field.name,
                            fieldType: field.type,
                            fieldInnerType: field.innerType,
                            id: fieldId
                        })
                        return preVal
                    }
                    if (validateInnerType(field.innerType)) {
                        preVal.push({
                            icon: getFieldIcon(field.id, field.type, field.innerType),
                            title: field.name,
                            fieldType: field.type,
                            fieldInnerType: field.innerType,
                            id: fieldId
                        })
                    }

                    return preVal
                },
                [],
                Object.entries(ds.schema)
            )
        }
        return [...preVal, dsOption]
    }, [])
    return { type: VariableSourceType.parentNode, list }
}
