import { Tracker } from '@lighthouse/shared'
import type { Router } from '@remix-run/router'
import React from 'react'
import type { RouteObject } from 'react-router-dom'
import { createBrowserRouter, redirect } from 'react-router-dom'

import http from '@/http'

import { applicationIdProxy } from './atoms/application/state'
import { getAppId } from './services'
import NotFoundTitle from './views/404'
import BindEmail from './views/Account/BindEmail'
import BindMobile from './views/Account/BindMobile'
import BindWechat from './views/Account/BindWechat'
import Login from './views/Account/Login'
import Perfect from './views/Account/Perfect'
import Register from './views/Account/Register'
import Review from './views/Account/Review'
import { Layout } from './views/Layout'
import Page from './views/Page'
import PageDetail from './views/Page/[id]'
import NoAccess from './views/Page/NoAccess'
import { StripePay } from './views/StripePay'
import Unpublished from './views/Unpublished'
import WechatPay from './views/WechatPay'

export const routes: RouteObject[] = [
    {
        path: '/',
        element: <Layout />,
        loader: async () => {
            const { host } = window.location
            // const [appId] = await getAppId('.test01.byecode.site')
            const [appId] = await getAppId(host.replace(':5273', ''))
            // const appId = 'f3eae9f4f6114111a090817351c27501'
            applicationIdProxy.id = appId
            // 初始化用户行为埋点 明杠说不需要了
            // const tracker = new Tracker()
            // tracker.init({ requestUrl: `/lighthouse/api/auth/v1/${appId}/tracking` })
            // 手动触发 pageLoad 埋点
            // tracker.sendTracker('load', 'innerPageLoad')
            return { appId }
        },
        children: [
            {
                path: '/account',
                children: [
                    {
                        path: 'login',
                        element: <Login />
                    },
                    {
                        path: 'register',
                        element: <Register />
                    },
                    {
                        path: 'perfect',
                        element: <Perfect />
                    },
                    {
                        path: 'bindMobile',
                        element: <BindMobile />
                    },
                    {
                        path: 'bindEmail',
                        element: <BindEmail />
                    },
                    {
                        path: 'bindWechat',
                        element: <BindWechat />
                    },
                    {
                        path: 'review',
                        element: <Review />
                    }
                ]
            },
            {
                path: '/pay/',
                element: <WechatPay />
            },
            {
                path: '/pay/stripe',
                element: <StripePay />
            },
            {
                // 统一通过根路由做应用 id 的处理逻辑，这里是阻塞应用加载的
                // 只有应用 id 获取到以后，才能进行接下来的处理
                path: '/',
                element: <Page />,
                children: [
                    // 兼容之前路由
                    {
                        path: 'page/:id',
                        loader: ({ params, request }) => {
                            const query = request.url.split('?')[1]
                            if (query) {
                                const search = new URLSearchParams(query)
                                // 详情页
                                if (search.has('a') || search.has('d') || search.has('r')) {
                                    return redirect('/')
                                }
                            }

                            return redirect(`/P/${params.id}`)
                        }
                    },
                    // 新路由
                    {
                        path: 'P',
                        children: [
                            {
                                path: 'noAccess',
                                element: <NoAccess />
                            },
                            {
                                path: ':id/*?',
                                element: <PageDetail />,
                                loader: ({ params: { id = '' } }) => {
                                    http.defaults.headers.common['pageId'] = id
                                    return id
                                }
                            }
                        ]
                    },
                    {
                        path: '*',
                        children: [
                            {
                                path: 'noAccess',
                                element: <NoAccess />
                            },
                            {
                                path: '*?',
                                element: <PageDetail />
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        path: '/404',
        element: <NotFoundTitle />
    },
    {
        path: '/unpublished',
        element: <Unpublished />
    }
]

export const router = createBrowserRouter(routes)
