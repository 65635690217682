import type { Device, FilterFormType, RecordData } from '@lighthouse/core'
import { filterRecordChecker, getWindowUrl, useAtomData } from '@lighthouse/shared'
import { getBrowser } from '@lighthouse/tools'
import { find, isEmpty } from 'rambda'
import { useCallback } from 'react'

import { recordPoolAtom } from '@/atoms/dataSource/state'

import { useCurrentAppId } from './useApplication'
import { useDataSourceList } from './useDataSource'
import type { usePageDataSourceForVariableSelector } from './usePage'
import { useUserRecord } from './useUserRecord'

type Props = Pick<ReturnType<typeof usePageDataSourceForVariableSelector>, 'curr' | 'prev'>

export const useVisibilityFilter = ({ prev, curr }: Props) => {
    const userRecord = useUserRecord()

    const appId = useCurrentAppId()
    const dataSourceList = useDataSourceList(appId)
    const prevRecord = useAtomData(
        recordPoolAtom,
        useCallback(
            draft => find(item => item.appId === appId && item.dsId === prev.datasource?.id && item.id === prev.recordId, draft),
            [appId, prev.datasource?.id, prev.recordId]
        )
    )
    const currRecord = useAtomData(
        recordPoolAtom,
        useCallback(
            draft => find(item => item.appId === appId && item.dsId === curr.datasource?.id && item.id === curr.recordId, draft),
            [appId, curr.datasource?.id, curr.recordId]
        )
    )

    return useCallback(
        (payload: { visibilityFilter?: FilterFormType; viewRecord?: RecordData; device?: Device[] }) => {
            const { visibilityFilter, viewRecord, device } = payload
            const filterVisible =
                visibilityFilter && !isEmpty(visibilityFilter)
                    ? filterRecordChecker({
                          filter: visibilityFilter,
                          dataSourceList,
                          currentRecord: {
                              datasource: curr.datasource,
                              record: currRecord
                          },
                          prevRecord: {
                              datasource: prev.datasource,
                              record: prevRecord
                          },
                          viewRecord,
                          userRecord,
                          pageStackFormState: curr.formState,
                          currentPageLink: getWindowUrl()
                      })
                    : true
            const browser = getBrowser()
            const result = browser.parseUserAgent()
            const isMobile = result && result.isMobile
            const deviceVisible = device ? (isMobile && device.includes('mobile')) || (!isMobile && device.includes('desktop')) : true
            return filterVisible && deviceVisible
        },
        [curr.datasource, curr.formState, currRecord, dataSourceList, prev.datasource, prevRecord, userRecord]
    )
}
