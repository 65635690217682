import { SubFormBlock } from '@lighthouse/block'
import { Toast } from '@lighthouse/bui'
import { type SubFormBlockAbstract, type ViewRecordOperateProtocol, RecordOpenType } from '@lighthouse/core'
import {
    aliyunVideoProtocolList,
    fieldFileFilter,
    fileMaxUploadSize,
    fileSuffixRegex,
    getFileSizeToMB,
    getUrlNameByOpenType,
    useAtomData,
    useFormModuleSubForm
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router'

import { languageAtom } from '@/atoms/application/state'
import { pageAtomFamily, pageStackAtomFamily, pageStackOfFieldBlockAtom } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/context/PageContext'
import { useCurrentAppId, useCurrentEnvId, usePreview } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlockDefaultValueRender } from '@/hooks/useFieldBlockDefaultValueRender'
import { useFieldBlockMethods } from '@/hooks/useFieldBlockMethods'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import * as srv from '@/services'
import { uploadInDataSourceManagerParams, uploadVideoInDataSourceManagerParams } from '@/utils/auth'

interface SubFormBlockControllerProps {
    blockData: SubFormBlockAbstract
    onBlockChange?: (values: SubFormBlockAbstract, origin: SubFormBlockAbstract) => Promise<void> | void
}

const SubFormBlockController: React.FunctionComponent<SubFormBlockControllerProps> = ({ blockData, onBlockChange }) => {
    const {
        config: { columns = [], pointer = '' },
        id: blockId
    } = blockData

    const onVariableValueRender = useFieldBlockDefaultValueRender(pointer)

    const { defaultValue: dataList, subFormErrors: errors, onChangeSubForm, onSubFormInputChange, onSubFormInputBlur } = useFormModuleSubForm(blockId)
    const appId = useCurrentAppId()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId)
    const previewType = usePreview()
    const navigate = useNavigate()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const dataSource = useDataSource(appId, envId, pointer)
    const language = useAtomData(languageAtom)
    const fieldBlockValueMap = useAtomData(
        pageStackOfFieldBlockAtom,
        useCallback(s => s?.[stackId], [stackId])
    )
    const { handleRenderTitle } = useRichTextToTitle()

    const pageName = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.name ?? '', [])
    )

    const handleOpenPage = useCallback(
        (params: ViewRecordOperateProtocol['creatingConfig']) => {
            const { page, openType = RecordOpenType.page } = params ?? {}
            if (page) {
                navigate(`./${getUrlNameByOpenType(openType)}/${page}`, { relative: 'path' })
            }
        },
        [navigate]
    )

    const handleFetchSmsCode = useCallback((mobile: string) => srv.getVerifyCode({ mobile, blockId, pageId }), [blockId, pageId])

    /** 获取关联数据源数据 start */
    const relativeDataSourceConfig = useMemo(
        () =>
            columns.map(column => {
                const { config, id } = column
                const { inputType = 'text', fieldPointer = '', initialValue } = config ?? {}
                const initValue = onVariableValueRender({
                    id,
                    dsId: pointer,
                    inputType,
                    fieldId: fieldPointer,
                    initialValue,
                    config
                })
                return { config, value: initValue }
            }),
        [columns, onVariableValueRender, pointer]
    )
    const { onFetchDataSource, onLoadMoreData, relativeDataSource, onFetchCascadeOptions, onFetchPersonOptions } = useFieldBlockMethods({
        fieldBlocks: relativeDataSourceConfig,
        fieldBlockValueMap
    })
    /** 获取关联数据源数据end */

    const getUploadyOptions = useCallback(
        (fieldId: string) => ({
            // TODO: @kidrue id后续处理掉 不需要此参数
            info: { label: pageName, id: '', groupId: pageId },
            options: {
                ...uploadInDataSourceManagerParams({ dsId: pointer, appId, fieldId }),
                fileFilter: fieldFileFilter
            }
        }),
        [appId, pageId, pageName, pointer]
    )

    const getRichTextUploadOptions = useCallback(
        (fieldId: string) => uploadInDataSourceManagerParams({ dsId: pointer, appId, fieldId }),
        [appId, pointer]
    )

    const getVideoUploadyOption = useCallback(
        (fieldId: string) => {
            return {
                // TODO: @kidrue id后续处理掉 不需要此参数
                info: { label: pageName, id: '', groupId: pageId },
                options: {
                    ...uploadVideoInDataSourceManagerParams({ appId, dsId: pointer, fieldId }),
                    fileFilter: (file: File | string, index: number) => {
                        if (file instanceof File) {
                            if (file.size > fileMaxUploadSize) {
                                Toast.error(`不能上传大于 ${getFileSizeToMB(fileMaxUploadSize)}mb 的文件`)
                                return false
                            }
                            const extension = fileSuffixRegex.exec(file.name.toLocaleLowerCase())?.[1]?.toLocaleLowerCase()
                            if (!extension || !aliyunVideoProtocolList.includes(extension)) {
                                Toast.error('视频格式错误，请上传 MP4 格式的视频')
                                return false
                            }
                            return true
                        }
                        return true
                    }
                }
            }
        },
        [appId, pageId, pageName, pointer]
    )

    return (
        <SubFormBlock
            appId={appId}
            envId={envId}
            dataList={dataList}
            isValid={false}
            blockData={blockData}
            previewType={previewType}
            dataSource={dataSource}
            relativeDataSource={relativeDataSource}
            dataSourceList={dataSourceList}
            language={language}
            errors={errors}
            getUploadyOptions={getUploadyOptions}
            getRichTextUploadOptions={getRichTextUploadOptions}
            getVideoUploadyOption={getVideoUploadyOption}
            onChange={onChangeSubForm}
            onBlockChange={onBlockChange}
            onInputChange={onSubFormInputChange}
            onVariableValueRender={onVariableValueRender}
            onOpenPage={handleOpenPage}
            onFetchSmsCode={handleFetchSmsCode}
            onFetchCascadeOptions={onFetchCascadeOptions}
            onFetchDataSource={onFetchDataSource}
            onLoadMoreData={onLoadMoreData}
            onFetchPersonOptions={onFetchPersonOptions}
            onRenderTitle={handleRenderTitle}
            onInputBlur={onSubFormInputBlur}
        />
    )
}

export default SubFormBlockController
